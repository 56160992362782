import { useState, React } from 'react';
import { Navbar, Container, Nav, Offcanvas, Overlay, Popover, Form, ProgressBar, Figure, Stack } from 'react-bootstrap';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { GaugeBMI } from './react-mdkku-chart.js';
import { error, lang, units } from './config.lang.js';

const entering = (e) => {
  e.style.fontFamily = 'Pridi';
}

const calculateBMI = (w, h) => {
  h = h / 100;
  return (w / (h * h)).toFixed(2);
}

export const Navbarmod = (props) => {
  return (
    <Navbar sticky="top" className="bg-opacity-50 shadow nav" variant="light" expand="lg">
      <Container fluid>
        <Navbar.Brand href="#" className="d-flex align-items-center">
          <img src={props.logo} height="75" className="d-inline-block align-top" alt="logo" />
          <div className="d-block ms-3">
            <h4 className="mt-1 site-title">{props.lang.site_title}</h4>
            <div className="d-flex justify-content-center">
              <div className="hr-line"></div>
              <div className="hr-icon">{props.lang.site_title_sep}</div>
              <div className="hr-line"></div>
            </div>
            <h6 className="text-center site-subtitle">{props.lang.site_subtitle}</h6>
          </div>
        </Navbar.Brand>
        {
          (false) ? [
            <div>
              <Navbar.Toggle aria-controls="offCanvasMen" />
              <Navbar.Offcanvas id="offCanvas1" aria-labelledby="offCanvasNavbarLabel" placement="end">
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link href="#">Home</Nav.Link>
                    <Nav.Link href="#">Link</Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </div>
          ] : []
        }
      </Container>
    </Navbar>
  );
}

export const SidebarCollapse = (props) => {

  const [canvas1, setCanvas1] = useState(false);
  const [canvas2, setCanvas2] = useState(false);

  const canvas0ele = props.data.sections[0].generalInput.map((k, i) => {
    let altv = (props.data.sections[0][k].altLabel != undefined && props.data.sections[0][k].altLabel === "อายุ") ? <div>{props.data.sections[0][k].altValue[0] + " " + lang.year}</div> : [];
    let v = (props.data.sections[0][k].value instanceof Date) ? props.data.sections[0][k].value.getDate() + "/" + props.data.sections[0][k].value.getMonth() + "/" + (parseInt(props.data.sections[0][k].value.getYear()) + 1900 + 543) : props.data.sections[0][k].value;
    v = (props.data.sections[0][k].options != undefined) ? props.data.sections[0][k].options.map((k, i) => { if (k.value == v) { return k.label; } }) : v;
    return (<Stack gap={3} direction='horizontal'>
      <div className="p-2">{props.data.sections[0][k].label}</div>
      <div className="p-2">{v}</div>
      {altv}
    </Stack>);
  });
  const canvas1ele = props.data.sections[0].topicList.map((k, i) => {
    let lst2 = Object.keys(props.data.sections[0][k].commonInput).map((l, i) => {
      let chk = (props.data.sections[0][k].commonInput[l].type == "RadioSwitch" && props.data.sections[0][k].commonInput[l].value == true) ? <i className="fa fa-check-circle text-success"></i> : <i className="fa fa-minus-circle text-danger"></i>;
      let v = (props.data.sections[0][k].commonInput[l].type == "RadioSwitch") ? chk : props.data.sections[0][k].commonInput[l].value;
      v = (props.data.sections[0][k].commonInput[l].options != undefined) ? props.data.sections[0][k].commonInput[l].options.map((j, i) => { if (j.value == v) { return j.label; } }) : v;
      if (v !== "") {
        return (<Stack gap={3} direction="horizontal">
          <div className="p-2">{props.data.sections[0][k].commonInput[l].label}</div>
          <div className="p-2">{v}</div>
        </Stack>);
      }
    });
    let out = (<Stack gap={1}><h4 style={{ borderBottom: '1px solid gray' }}>{props.data.sections[0][k].title}</h4>{lst2}</Stack>);
    return out;
  });
  const tgt = (props.data.section2His.length > 0) ? props.data.section2His[0].val : props.data.sections[1];
  const canvas2ele = tgt.topicList.map((k, i) => {
    let lst2 = Object.keys(tgt[k].commonInput).map((l, i) => {
      let chk = (tgt[k].commonInput[l].type == "RadioSwitch" && tgt[k].commonInput[l].value == true) ? <i className="fa fa-check-circle text-success"></i> : <i className="fa fa-minus-circle text-danger"></i>;
      let v = (tgt[k].commonInput[l].type == "RadioSwitch") ? chk : tgt[k].commonInput[l].value;
      v = (tgt[k].commonInput[l].options != undefined) ? tgt[k].commonInput[l].options.map((j, i) => { if (j.value == v) { return j.label; } }) : v;
      if (v !== "") {
        return (<Stack gap={3} direction="horizontal">
          <div className="p-2">{tgt[k].commonInput[l].label}</div>
          <div className="p-2">{v}</div>
        </Stack>);
      }
    });
    let out = (<Stack gap={1}><h4 style={{ borderBottom: '1px solid gray' }}>{tgt[k].title}</h4>{lst2}</Stack>);
    return out;
  });

  let btnCanvas2 = (props.data.page == 2) ? (
    <Nav.Item>
      <Nav.Link className="text-secondary" href="#" onClick={(e) => { setCanvas1(false); setCanvas2(true) }}><i className="bi bi-journal-check fs-4"></i></Nav.Link>
    </Nav.Item>
  ) : [];

  return (
    <Nav className="fixed-top border text-secondary fs-6 rounded sidebar-nav" style={{ marginTop: '101px' }}>
      <Nav.Item>
        <Nav.Link className="text-secondary" href="#" onClick={(e) => { props.handler(-1) }}><i className="bi bi-house-fill fs-4"></i></Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link className="text-secondary" href="#" onClick={(e) => { setCanvas2(false); setCanvas1(true); }}><i className="bi bi-person-lines-fill fs-4"></i></Nav.Link>
      </Nav.Item>
      {btnCanvas2}
      <Offcanvas show={canvas1} onHide={(e) => { setCanvas1(false) }} style={{ marginTop: '101px', paddingLeft: '50px' }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Patient Info.</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Stack gap={3}>
            <Stack gap={1}><h4 style={{ borderBottom: '1px solid gray' }}>Profile</h4>{canvas0ele}</Stack>
            {canvas1ele}
          </Stack>
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas show={canvas2} onHide={(e) => { setCanvas2(false) }} style={{ marginTop: '101px', paddingLeft: '50px' }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Operative Day</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Stack gap={3}>
            <Stack direction='horizontal' gap={3}><div className='font-weight-bold'>Date</div><div>{props.data.section2due.toString().slice(0, props.data.due.toString().indexOf("GMT"))}</div></Stack>
            {canvas2ele}
          </Stack>
        </Offcanvas.Body>
      </Offcanvas>
    </Nav>
  );
}

export const Footer = (props) => {
  return (
    <Container className="p-4 text-center shadow site-footer">
      <span>{props.lang.footer_affiliation}</span><br />
      <span><i className="bi bi-envelope-fill"></i>&nbsp;{props.lang.footer_mail}&nbsp;&nbsp;&nbsp;&nbsp;<i className="bi bi-telephone-fill"></i>&nbsp;{props.lang.footer_phone}</span>
    </Container>
  );
}

export const OffcanvasProgressTop = (props) => {
  return (
    <Offcanvas show={props.show} placement="top" onEntered={(e) => { props.handler(-1) }}>
      <Offcanvas.Header>
        <Offcanvas.Title>{props.title}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ProgressBar animated now={props.value} />
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export const InputNotify = (props) => {
  return (
    <div>
      <Overlay onEntering={props.entering} target={props.target} show={!props.show} placement="right">
        <Popover className="w-25 text-danger p-2 fs-6" id="overlay-ex">
          {props.notifytxt}
        </Popover>
      </Overlay>
      <Overlay target={props.target} show={props.show} placement="right">
        <Popover className="text-danger p-2 fs-5" id="overlay-ex" {...props}>
          <i className="bi bi-check-circle-fill text-success"></i>
        </Popover>
      </Overlay>
    </div>
  );
}

export const InputValidation = (props) => {
  return (
    (props.show === undefined || props.show) ?
      <Form.Group className="mb-3">
        <Form.Label>{props.target.label}</Form.Label>
        <Form.Control type={props.type} label={props.target.label} ref={props.target.ref} value={props.target.value} onChange={(e) => { props.validation(props.section, props.target.rules, props.target, e.target.value, props.statekey) }} onFocus={(e) => { e.target.select() }}></Form.Control>
        <Overlay onEntering={(e) => { entering(e) }} target={props.target.ref} show={!props.target.valid} placement="right">
          <Popover className="w-25 text-danger p-2 fs-6" id="overlay-ex">
            {props.target.error}
          </Popover>
        </Overlay>
        <Overlay target={props.target.ref} show={props.target.valid} placement="right">
          <Popover className="text-danger p-2 fs-5" id="overlay-ex">
            <i className="bi bi-check-circle-fill text-success"></i>
          </Popover>
        </Overlay>
      </Form.Group> : []
  );
}

export const RadioSwitch = (props) => {
  return (
    (props.show === undefined || props.show) ?
      <Form className="mt-3">
        <Form.Check
          type="switch"
          id={props.statekey}
          label={props.target.label}
          checked={props.target.value}
          onChange={(e) => { props.validation(props.section, {}, props.target, e.target.checked, props.statekey) }}
        />
      </Form> : []
  );
}

export const InputOptional = (props) => {
  return (
    (props.show === undefined || props.show) ?
      <Form.Group className="mb-3">
        <Form.Label>{props.target.label}</Form.Label>
        <Form.Control type={props.type} label={props.target.label} ref={props.target.ref} value={props.target.value} onChange={(e) => { props.validation(props.section, props.target.rules, props.target, e.target.value, props.statekey) }} onFocus={(e) => { e.target.select() }}></Form.Control>
      </Form.Group> : []
  );
}

export const MultiValues = (props) => {
  let out = [];
  for (let i = 0; i < props.count; i++) {
    out.push(
      <Form.Group className="mb-1">
        <Form.Control type={props.type} label={props.target.label + "_1"} value={props.target.value[i]} onChange={(e) => { props.validation(props.section, props.target.rules, props.target, e.target.value, props.statekey, i) }} onFocus={(e) => { e.target.select() }}></Form.Control>
      </Form.Group>
    );
  }
  return (
    (props.show === undefined || props.show) ?
      out : []
  );
}

export const SelectionValue = (props) => {
  let out = props.target.options.map((k, i) => {
    let chk = (k.value == props.target.value) ? true : false;
    return (
      <Form.Check checked={chk} inline id={props.statekey + '_' + i} type="radio" value={k.value} label={k.label} name={props.statekey} onChange={(e) => { props.validation(props.section, props.target.rules, props.target, e.target.value, props.statekey) }} />
    );
  });
  return (
    (props.show === undefined || props.show) ?
      <Form.Group className="mb-1"><Form.Label className="me-4">{props.target.label}</Form.Label>{out}</Form.Group> : []
  );
}

export const SelectionValues = (props) => {
  let out = props.target.options.map((k, i) => {
    let chk = false;
    props.target.value.map((l, j) => { if (l === k) chk = true });
    return (
      <Form.Check checked={chk} inline id={props.statekey + '_' + i} type="checkbox" value={k} label={k} name={props.statekey} onChange={(e) => { props.validation(props.section, props.target.rules, props.target, e.target.value, props.statekey) }} />
    );
  });
  return (
    (props.show === undefined || props.show) ?
      <Form.Group className="mb-1"><Form.Label className="me-4">{props.target.label}</Form.Label>{out}</Form.Group> : []
  );
}

export const InputBirthdateAge = (props) => {

  return (
    <Form.Group className="mb-3">
      <Form.Label>{props.target.label}</Form.Label>
      <div className="w-100 custom-datepicker" ref={props.target.ref}>
        <DatePicker onSelect={(e) => { props.validation(props.section, props.target.rules, props.target, e, props.statekey) }} selected={props.target.value} readOnly={props.readonly} maxDate={new Date()} dateFormat="dd/MM/yyyy" showYearDropdown showMonthDropdown dropdownMode='select'></DatePicker>
      </div>
      <Form.Group className="mb-3">
        <Form.Label>{props.target.altLabel} : {props.target.altValue[0]} {lang.year}, {props.target.altValue[1]} {lang.month}, {props.target.altValue[2]} {lang.day}</Form.Label>
      </Form.Group>
      <Overlay onEntering={(e) => { entering(e) }} target={props.target.ref} show={!props.target.valid} placement="right">
        <Popover className="w-25 text-danger p-2 fs-6" id="overlay-ex">
          {props.target.error}
        </Popover>
      </Overlay>
      <Overlay target={props.target.ref} show={props.target.valid} placement="right">
        <Popover className="text-danger p-2 fs-5" id="overlay-ex" {...props}>
          <i className="bi bi-check-circle-fill text-success"></i>
        </Popover>
      </Overlay>
    </Form.Group>
  );
}

export const InputWeightHeightBMI = (props) => {

  return (
    <div>
      <InputValidation section={props.section} type="number" statekey={props.statekeyWeight} validation={props.validation} target={props.targetWeight} />
      <InputValidation section={props.section} type="number" statekey={props.statekeyHeight} validation={props.validation} target={props.targetHeight} />
      <Form.Group className="mb-3">
        <Form.Label>{props.targetWeight.altLabel} : {calculateBMI(props.targetWeight.value, props.targetHeight.value)} {units.bmi}</Form.Label>
      </Form.Group>
      <GaugeBMI data={[["Label", "Value"], ["BMI", calculateBMI(props.targetWeight.value, props.targetHeight.value)]]} />
    </div>
  );
}

export const InputUpload = (props) => {
  let imgshow = [];
  if (props.target.value !== undefined && props.target.value !== "" && props.target.value !== 0) {
    imgshow = (
      <Figure>
        <Figure.Image width={171} height={180} alt="Before Image" src={props.target.value} />
      </Figure>
    );
  }
  return (
    <div className="mt-2">
      {imgshow}
      <Form.Group className="mb-2">
        <Form.Label>
          {props.target.label}
        </Form.Label>
        <Form.Control type={props.type} label={props.target.label} onChange={(e) => { props.handler(props.section, props.statekey, props.target, e.target.files[0]) }}></Form.Control>
      </Form.Group>
    </div>
  );
}

export const SelectionDateTime = (props) => {

  return (
    <div className={"mt-2 uni-row"}>
      <h4 className="mt-2 mb-0"></h4>
      <Form.Group className="mb-3">
        <Form.Label>{props.target.label}</Form.Label>
        <div className="w-100 custom-datepicker" ref={props.target.ref}>
          <DatePicker onSelect={(e) => { props.handler(props.target, e) }} readOnly={props.readonly} selected={props.target.value} dateFormat="dd/MM/yyyy" showYearDropdown showMonthDropdown dropdownMode='select'></DatePicker>
        </div>
      </Form.Group>
    </div>
  );
}