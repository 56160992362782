import React, { Component } from 'react';
import { Container, Button, Form, NavDropdown, InputGroup, Dropdown, Stack, Spinner } from 'react-bootstrap';
import Cookies from 'universal-cookie';


import bgHead from './bg.png';
import logo from './logo.png';
import './App.css';
import { RadioSwitchDev, InputValidationDev, InputUploadDev, SelectionValueDev, Navbarmod, Footer, OffcanvasProgressTop, SidebarCollapse } from './react-mdkku-ui-questionare-dev';

import { lang } from './config.lang-dev';
import { endpoint } from './config.env';

const cookies = new Cookies();

//const endpoint = "https://comedkku.net/nosql/api4all.php?";
//const endpoint = "http://localhost/nosql/api4all.php?";

class App extends Component {

  constructor(props) {
    super(props);
    this.url = (props.params.table === undefined) ? endpoint + "b=meta" : endpoint + "b=" + props.params.table;
    this.state = {
      savingstate: false,
      savingprogress: 0,
      fetching: true,
      topicList: [
        'topic0',
      ],
      topic0:
      {
        id: 'topic0',
        title: 'test',
        edit: '',
        commonInput: {
          input0: { id: 'input0', type: 'RadioSwitch', label: 'Unnamed' },
          input1: { id: 'input1', type: 'RadioSwitch', label: 'Unnamed' }
        },
      }
    }
  }

  componentDidMount() {
    console.log(this.url);
    fetch(this.url + "&a=getlatest&d=d0")
      .then(res => res.json())
      .then((data) => {
        console.log(data);
        if (data.formdata !== undefined) {
          let nu = data.formdata[0].val;
          nu.savingstate = false;
          nu.fetching = false;
          this.setState(nu);
        }
        console.log(this.state);
      });
  }

  addComponent = (tp, ele) => {
    if (tp === "") {
      let c = (Object.keys(this.state.topicList).length > 0) ? Object.keys(this.state.topicList).map((k, i) => { return parseInt(k.replace("topic", "")) }).reduce((a, b) => { return (a > b) ? a : b }) : 0;
      let uid = 'topic' + (c + 1);
      let newtpl = this.state.topicList;
      newtpl.push(uid);
      let newtp = { id: uid, title: 'Untitled', edit: '', commonInput: {} };
      this.setState({ topicList: newtpl });
      let prev = this.state;
      prev[uid] = newtp;
      this.setState(prev);
    } else {
      let c = (Object.keys(this.state[tp].commonInput).length > 0) ? Object.keys(this.state[tp].commonInput).map((k, i) => { return parseInt(k.replace("input", "")) }).reduce((a, b) => { return (a > b) ? a : b }) : 0;
      let prev = this.state;
      prev[tp].commonInput['input' + (c + 1)] = { id: 'input' + (c + 1), type: ele, label: 'Unnamed' };
      this.setState(prev);
    }
  }

  removeComponent = (tp, ele) => {
    if (tp === "") {
      let idx = -1;
      this.state.topicList.map((k, i) => {
        if (ele === k) idx = i;
      });
      let oldst = this.state;
      this.setState(oldst);
      let oldtpl = this.state.topicList;
      oldtpl.splice(idx, 1);
      let oldstate = this.state;
      oldstate['topicList'] = oldtpl;
      this.setState(oldstate);
    } else {
      let oldtp = this.state[tp];
      let newtplist = {};
      Object.keys(this.state[tp].commonInput).map((k, i) => {
        if (k !== ele) newtplist[k] = this.state[tp].commonInput[k];
      });
      oldtp.commonInput = newtplist;
      let oldstate = this.state;
      oldstate[tp] = oldtp;
      this.setState(oldstate);
    }
  }

  swapComponents = (tp, a, b) => {
    if (tp === "") {
      let oldst = this.state;
      let tmp = oldst[a];
      oldst[a].id = b;
      oldst[b].id = a;
      oldst[a] = oldst[b];
      oldst[b] = tmp;
      this.setState(oldst);
    } else {
      let oldst = this.state;
      oldst[tp].commonInput[a].id = b;
      oldst[tp].commonInput[b].id = a;
      let tmp = oldst[tp].commonInput[a];
      oldst[tp].commonInput[a] = oldst[tp].commonInput[b];
      oldst[tp].commonInput[b] = tmp;
      this.setState(oldst);
    }
  }

  setTargetState = (tp, idx, target) => {
    let newtp = this.state[tp];
    if (idx === -1) {
      newtp.title = target;
    } else if (idx === -2) {
      newtp.single = target;
    } else {
      newtp.commonInput[idx] = target;
    }
    let oldstate = this.state;
    oldstate[tp] = newtp;
    this.setState(oldstate);
    //console.log(this.state);
  }

  setTargetEdit = (tp, target) => {
    let newtp = this.state[tp];
    newtp.edit = (target !== newtp.edit) ? target : "";
    let oldstate = this.state;
    oldstate[tp] = newtp;
    this.setState(oldstate);
    //console.log(this.state);
  }

  saveData = () => {
    this.setState({ savingprogress: 0 });
    let jstr0 = JSON.stringify(this.state);
    console.log(jstr0);
    let formDat = "v=" + jstr0;
    fetch(this.url + "&a=set&d=d0&f=formdata", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
      },
      body: formDat
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);
      });
    this.setState({ savingstate: false, savingprogress: 100 });
  }

  siblingSelection = (nd) => {
    let ele = Object.keys(this.state[nd].commonInput).map((k, i) => {
      return ((this.state[nd].commonInput[k].type === "RadioSwitch") ? <option key={nd + "_" + k} value={nd + "_" + k} ref={this.state[nd].commonInput[k].label}>{this.state[nd].commonInput[k].label}</option> : []);
    });
    return (ele);
  }

  render() {
    let mainout = (!this.state.fetching) ?
      this.state.topicList.map((k, i) => {
        let c = (i % 2 === 1) ? 'even' : 'odd';
        let sib = this.siblingSelection(k);
        let swapls = this.state.topicList.map((l, j) => { if (l !== k) return (<Dropdown.Item onClick={(e) => { this.swapComponents("", k, l) }} style={{ fontSize: '0.7em' }}>{l}</Dropdown.Item>) }).filter(x => x != undefined);
        let swapdd = (<Dropdown className='me-1 mt-2'><Dropdown.Toggle variant='secondary' id={"swap_" + k} style={{ fontSize: '0.7em' }}>Swap</Dropdown.Toggle><Dropdown.Menu>{swapls}</Dropdown.Menu></Dropdown>)
        return (
          [
            <div className={"mt-2 " + c + "-row"}>
              <div className="float-end d-flex">
                {swapdd}
                <i className="bi bi-dash-circle fs-4 d-block mt-2" role="button" onClick={(e) => { this.removeComponent('', k) }}></i>
                <NavDropdown title={(<i className="bi bi-plus-circle fs-4" role="button"></i>)} id="add-dropdown" align="start" className="float-end me-3 ms-2">
                  <NavDropdown.Item href="#" onClick={(e) => { this.addComponent(k, 'RadioSwitch') }}>{lang.questionare.yes_no_choices}</NavDropdown.Item>
                  <NavDropdown.Item href="#" onClick={(e) => { this.addComponent(k, 'SelectValue') }}>{lang.questionare.multiple_choices}</NavDropdown.Item>
                  <NavDropdown.Item href="#" onClick={(e) => { this.addComponent(k, 'TextInput') }}>{lang.questionare.text_input}</NavDropdown.Item>
                  <NavDropdown.Item href="#" onClick={(e) => { this.addComponent(k, 'NumberInput') }}>{lang.questionare.number_input}</NavDropdown.Item>
                  <NavDropdown.Item href="#" onClick={(e) => { this.addComponent(k, 'FileInput') }}>{lang.questionare.image_input}</NavDropdown.Item>
                </NavDropdown>
              </div>
              <h4 className="mt-3">
                {this.state[k].title}&nbsp;<i role="button" className="bi bi-pencil-square" onClick={(e) => { this.setTargetEdit(k, k) }}>&nbsp;({k})</i>
                {(this.state[k].edit === k) ?
                  <div className="d-flex">
                    <Form.Control type="text" label={lang.formlabel} value={this.state[k].title} onChange={(e) => { this.setTargetState(k, -1, e.target.value); }} />
                    <InputGroup className="ms-3">
                      <Form.Check className="text-nowrap mt-2 fs-6" type="switch" id={this.state[k].title + '-check-single'} checked={(this.state[k].single !== undefined) ? this.state[k].single : false} label={lang.questionare.single_value} onChange={(e) => { this.setTargetState(k, -2, e.target.checked) }} />
                    </InputGroup>
                  </div> : []
                }
              </h4>
              {
                Object.keys(this.state[k].commonInput).map((l, j) => {
                  let eleout = [];
                  let swapls2 = Object.keys(this.state[k].commonInput).map((m, a) => { if (m !== l) return (<Dropdown.Item onClick={(e) => { this.swapComponents(k, l, m) }}>{m}</Dropdown.Item>) }).filter(x => x != undefined);
                  let swapdd2 = (<Dropdown><Dropdown.Toggle variant='secondary' style={{ fontSize: '0.5em' }} id={"swap_" + k + "_" + l}>SW</Dropdown.Toggle><Dropdown.Menu>{swapls2}</Dropdown.Menu></Dropdown>)
                  if (this.state[k].commonInput[l].type === "RadioSwitch") {
                    eleout = <div className="d-flex"><RadioSwitchDev topic={this.state.topicList[i]} edit={this.state[k].edit === l} target={this.state[k].commonInput[l]} handler={this.setTargetState} editHandler={this.setTargetEdit} removeHandler={this.removeComponent} sibling={sib} /></div>;
                  } else if (this.state[k].commonInput[l].type === "SelectValue") {
                    eleout = <div className="d-flex"><SelectionValueDev topic={this.state.topicList[i]} edit={this.state[k].edit === l} target={this.state[k].commonInput[l]} handler={this.setTargetState} editHandler={this.setTargetEdit} removeHandler={this.removeComponent} sibling={sib} /></div>;
                  } else if (this.state[k].commonInput[l].type === "TextInput" || this.state[k].commonInput[l].type === "OptionalTextInput") {
                    eleout = <div className="d-flex"><InputValidationDev type="text" topic={this.state.topicList[i]} edit={this.state[k].edit === l} target={this.state[k].commonInput[l]} handler={this.setTargetState} editHandler={this.setTargetEdit} removeHandler={this.removeComponent} sibling={sib} /></div>;
                  } else if (this.state[k].commonInput[l].type === "NumberInput" || this.state[k].commonInput[l].type === "OptionalNumberInput") {
                    eleout = <div className="d-flex"><InputValidationDev type="number" topic={this.state.topicList[i]} edit={this.state[k].edit === l} target={this.state[k].commonInput[l]} handler={this.setTargetState} editHandler={this.setTargetEdit} removeHandler={this.removeComponent} sibling={sib} /></div>;
                  } else if (this.state[k].commonInput[l].type === "FileInput") {
                    eleout = <div className="d-flex"><InputUploadDev type="file" topic={this.state.topicList[i]} edit={this.state[k].edit === l} target={this.state[k].commonInput[l]} handler={this.setTargetState} editHandler={this.setTargetEdit} removeHandler={this.removeComponent} sibling={sib} /></div>;
                  }
                  return (<Stack direction='horizontal' gap={3}>{eleout}<div><span style={{ fontSize: '0.6em', fontStyle: 'italic' }}>{l}</span><br />{swapdd2}</div></Stack>);
                })
              }
            </div>
          ]
        );
      }) : (<div className='text-center'><Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" /></div>);

    return (
      <div className='App'>
        <header className='App-header-dev'>
          <Navbarmod lang={lang} logo={logo} addHandler={this.addComponent} />
          <SidebarCollapse />
          <div className="container main-container">
            {mainout}
            {(!this.state.fetching && this.state.topicList.length > 0) ?
              <Button className="mb-5 mt-4 w-100 rounded-pill" variant='primary' onClick={(e) => { this.setState({ savingstate: true }) }}>{lang.questionare.save}</Button>
              : []
            }
            <OffcanvasProgressTop handler={this.saveData} show={this.state.savingstate} value={this.state.savingprogress} title={lang.saving} />
          </div>
        </header>
        <Footer lang={lang} />
      </div>
    );
  }
}

export default App;
