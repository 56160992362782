import React from 'react';
import { Chart } from 'react-google-charts';
import { Badge } from 'react-bootstrap';

export const GaugeBMI = (props) => {
    let opts = {
        width:120,
        height:120,
        greenFrom:18.5,
        greenTo:24.9,
        yellowFrom:25.0,
        yellowTo:29.9,
        redFrom:30,
        redTo:100,
        minorTicks:5,
        is3D:true,
        min:0,
        max:40,
    };

    props.data[1][1] = Math.round(props.data[1][1]);
    props.data[1][1] = (Number.isInteger(props.data[1][1]))?props.data[1][1]:0;
    
    return(
        <div className="d-flex align-items-center justify-content-center bg-secondary rounded-pill">
            <Chart
                chartType="Gauge"
                data={props.data}
                options={opts}
            />
            <div className="flex-fill">
                <Badge bg="light" className="ms-1 text-black shadow">Underweight</Badge>
                <Badge bg="success" className="ms-1 shadow">Health Weight</Badge>
                <Badge bg="warning" className="ms-1 text-black shadow">Overweight</Badge>
                <Badge bg="danger" className="ms-1 shadow">Obesity</Badge>
            </div>
        </div>
    );
}