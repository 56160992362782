import React, { Component } from 'react';
import { Container, Button, Form, Spinner, Alert, Accordion, Stack, Dropdown, Offcanvas, Popover, OverlayTrigger } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";

import logo from './logo.png';
import bgHead from './bg.png';
import './App.css';
import {
  Navbarmod, Footer, OffcanvasProgressTop, InputValidation, RadioSwitch,
  InputOptional, MultiValues, SelectionValue, SelectionValues, InputBirthdateAge,
  InputWeightHeightBMI, InputUpload, SidebarCollapse, SelectionDateTime
} from './react-mdkku-ui-mod';
import { GaugeBMI } from './react-mdkku-chart';

import { lang, field, error, units } from './config.lang';
import { endpoint } from './config.env';

const cookies = new Cookies();

//const endpoint = "https://comedkku.net/nosql/api4all.php?";
//const endpoint = "http://localhost/nosql/api4all.php?";

const parseValidation = (idx, target, data, handler, handlerDate) => {
  let nu = data;
  nu.savingstate = false;
  nu.topicList = data.topicList;
  nu.topicList.map((k, i) => {
    Object.keys(nu[k].commonInput).map((l, j) => {
      let emptyval = (nu[k].commonInput[l].type === "TextInput" || nu[k].commonInput[l].type === "OptionalTextInput") ? '' : 0;
      nu[k].commonInput[l].value = (nu[k].commonInput[l].value !== undefined) ? nu[k].commonInput[l].value : emptyval;
      nu[k].commonInput[l].valid = true;
      nu[k].commonInput[l].error = '';
      nu[k].commonInput[l].ref = React.createRef();
    });
  });
  target = nu;
  if (target.generalInput !== undefined) {
    target.generalInput.map((k, i) => {
      if (target[k] === undefined) {
        alert(k + "::" + target[k]);
      } else {
        target[k].ref = React.createRef();
        if (k === "pbirth") {
          handlerDate(idx, "pbirth", new Date(target[k].value));
        } else {
          handler(idx, target[k].rules, target[k], target[k].value, k, true);
        }
      }
    });
  }
  target.topicList.map((l, j) => {
    Object.keys(target[l].commonInput).map((k, i) => {
      if (target[l].commonInput[k] === undefined) {
        alert(k + "::" + target[k]);
      } else {
        //console.log(this.state[l].commonInput[k]);
        handler(idx, target[l].commonInput[k].rules, target[l].commonInput[k], target[l].commonInput[k].value, l + '_' + k, true);
      }
    });
  });
}

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      uid: props.params.uid,
      user: cookies.get('user'),
      loading: true,
      page: -1,
      due: new Date(),
      validation: true,
      savedstate: false,
      savingstate: false,
      chkOperative: false,
      chkFollow: 0,
      sections: [],
      section3: [],
      section2: undefined,
      section2due: new Date(),
      section2re: false,
      section2His: [],
      exptriger: false,
      expdata: '',
      exptemp: '[BW]\n[Symptom]',
      temptriger: false,
    };
    let dd = new Date();
    dd.setDate(dd.getDate() + 0);
    this.state.due = dd;

    fetch(endpoint + "a=get&b=ins&d=d0&u=" + props.params.uid)
      .then(res => res.json())
      .then((data) => {
        if (data.data !== undefined) {
          let nu = data.data[0].val;
          nu.savingstate = false;
          nu.topicList = data.data[0].val.topicList;
          nu.topicList.map((k, i) => {
            Object.keys(nu[k].commonInput).map((l, j) => {
              let emptyval = (nu[k].commonInput[l].type === "TextInput" || nu[k].commonInput[l].type === "OptionalTextInput") ? '' : 0;
              nu[k].commonInput[l].value = (nu[k].commonInput[l].value !== undefined) ? nu[k].commonInput[l].value : emptyval;
              nu[k].commonInput[l].valid = true;
              nu[k].commonInput[l].error = '';
              nu[k].commonInput[l].ref = React.createRef();
            });
          });
          this.state.sections[0] = nu;
          this.state.sections[0].generalInput.map((k, i) => {
            if (this.state.sections[0][k] === undefined) {
              alert(k + "::" + this.state.sections[this.state.page][k]);
            } else {
              this.state.sections[0][k].ref = React.createRef();
              if (k === "pbirth") {
                this.validateInput(0, "pbirth", new Date(this.state.sections[0].pbirth.value));
              } else {
                this.validateCommonInput(0, this.state.sections[0][k].rules, this.state.sections[0][k], this.state.sections[0][k].value, k, true);
              }
            }
          });
          this.state.sections[0].topicList.map((l, j) => {
            Object.keys(this.state.sections[0][l].commonInput).map((k, i) => {
              if (this.state.sections[0][l].commonInput[k] === undefined) {
                alert(k + "::" + this.state.sections[0][k]);
              } else {
                //console.log(this.state[l].commonInput[k]);
                this.validateCommonInput(0, this.state.sections[0][l].commonInput[k].rules, this.state.sections[0][l].commonInput[k], this.state.sections[0][l].commonInput[k].value, l + '_' + k, true);
              }
            });
          });
          fetch(endpoint + "a=list&b=ins&d=d0&o=DESC&w2=" + props.params.uid)
            .then(res => res.json())
            .then(data => {
              console.log("list", data.length);
              if (data.length == 0) {
                fetch(endpoint + "a=getlatest&b=bariatric2&d=d0")
                  .then(res => res.json())
                  .then(data => {
                    this.state.sections[1] = data.formdata[0].val;
                    parseValidation(1, this.state.sections[1], data.formdata[0].val, this.validateCommonInput);
                    this.setState({ loading: false });
                    this.getSection3(this.state);
                  });
              } else {
                let s2 = undefined;
                let cur_tm = 0;
                let s2his = [];
                var follcount = 0;
                for (var i = 0; i < data.length; i++) {
                  console.log(data[i]);
                  if (data[i].data[0].val.page != 2) {
                    if (data[i].data[0].tm > cur_tm) {
                      s2 = data[i];
                      cur_tm = data[i].data[0].tm;
                      s2his.push({ tm: data[i].data[0].tm, val: data[i].data[0].val.sections[1] });
                    } else {
                      s2his.push({ tm: data[i].data[0].tm, val: data[i].data[0].val.sections[1] });
                    }
                  } else {
                    this.state.section3[i] = data[i].data[0].val.sections[2];
                    this.state.section3[i].due = data[i].data[0].val.due;
                    follcount += 1;
                  }
                }
                this.state.section2His = s2his;
                if (s2 != undefined) {
                  this.state = s2.data[0].val;
                  this.state.section2due = new Date(this.state.due);
                  this.state.due = new Date();
                  this.state.chkOperative = true;
                  this.state.savingstate = false;
                  this.state.savedstate = false;
                  this.state.chkFollow = follcount;
                  parseValidation(0, this.state.sections[0], s2.data[0].val.sections[0], this.validateCommonInput, this.validateInput);
                  parseValidation(1, this.state.sections[1], s2.data[0].val.sections[1], this.validateCommonInput, this.validateInput);
                  this.setState({ page: -1 });
                }else{
                  this.state = data[0].data[0].val;
                  this.state.section2due = new Date(this.state.due);
                  this.state.due = new Date();
                  this.state.chkOperative = false;
                  this.state.savingstate = false;
                  this.state.savedstate = false;
                  this.state.chkFollow = follcount;
                  parseValidation(0, this.state.sections[0], data[0].data[0].val.sections[0], this.validateCommonInput, this.validateInput);
                  parseValidation(1, this.state.sections[1], data[0].data[0].val.sections[1], this.validateCommonInput, this.validateInput);
                  this.setState({ page: -1 });
                }
                this.getSection3(this.state);
              }
            });
        } else {
          window.location.href = "/";
        }
      });
  }

  componentDidMount() {
    //console.log(this.state);
  }

  getSection3(s) {
    fetch(endpoint + "a=getlatest&b=bariatric3&d=d0")
      .then(res => res.json())
      .then(data => {
        s.sections[2] = data.formdata[0].val;
        parseValidation(2, s.sections[2], data.formdata[0].val, this.validateCommonInput);
        let tmpstr = "";
        s.sections[2].topicList.map((k, i) => {
          if (s.sections[2][k].title !== "") {
            tmpstr += "[" + s.sections[2][k].title + "]\n";
            Object.keys(s.sections[2][k].commonInput).map((l, j) => {
              tmpstr += "   - [" + s.sections[2][k].commonInput[l].label + "]\n";
            });
          } else {
            Object.keys(s.sections[2][k].commonInput).map((l, j) => {
              tmpstr += "[" + s.sections[2][k].commonInput[l].label + "]\n";
            });
          }
        });
        this.setState({ exptemp: tmpstr, loading: false });
        console.log(s);
      });
  }

  setCookie = (t, e) => {
  }

  setDue = (tgt, e) => {
    this.setState({ due: e });
  }

  validateInput = (i, t, e) => {
    let prev = this.state.sections[i].pbirth;
    let chk = true;
    if (t === "pbirth") {
      if (e === "") {
        prev.valid = false;
        prev.error = error.is_empty;
      } else {
        prev.value = e;
        let age = this.calculateAge(e);
        prev.altValue = age;
        if (age[0] < 2) {
          prev.valid = false;
          prev.error = error.age_too_low;
        } else {
          prev.valid = true;
          prev.error = '';
        }
      }
    }
    let prevstate = this.state;
    prevstate.sections[i][t] = prev;
    this.setState(prevstate);
    return 1;
  }

  validateCommonInput = (i, rules, target, e, statekey, firsttime = false) => {
    let arr = statekey.split("_");
    target.value = e;
    if (rules !== undefined) {
      Object.keys(rules).map((k, i) => {
        if (k === "minNumber") {
          if (!isNaN(rules[k]) && rules[k] !== null && e < rules[k]) {
            target.error = (i > 0) ? target.error + "," + error.is_not_less_than + " " + rules[k] : error.is_not_less_than + " " + rules[k];
            target.valid = false;
          } else {
            target.error = (i > 0) ? target.error : '';
            target.valid = (i > 0) ? target.valid & true : true;
          }
        } else if (k === "maxNumber") {
          if (!isNaN(rules[k]) && rules[k] !== null && e > rules[k]) {
            target.error = (i > 0) ? target.error + "," + error.is_not_more_than + " " + rules[k] : error.is_not_more_than + " " + rules[k];
            target.valid = false;
          } else {
            target.error = (i > 0) ? target.error : '';
            target.valid = (i > 0) ? target.valid & true : true;
          }
        } else if (k === "notEmpty") {
          if (e === "") {
            target.error = (i > 0) ? target.error + "," + error.is_empty : error.is_empty;
            target.valid = false;
          } else {
            target.error = (i > 0) ? target.error : '';
            target.valid = (i > 0) ? target.valid & true : true;
          }
        } else if (k === "type") {
          if (rules[k] === "digit") {
            if (isNaN(e)) {
              target.error = (i > 0) ? target.error + "," + error.not_a_number : error.not_a_number;
              target.valid = false;
            } else {
              target.error = (i > 0) ? target.error : '';
              target.valid = (i > 0) ? target.valid & true : true;
            }
          }
        } else if (k === "minLength") {
          if (!isNaN(rules[k]) && rules[k] !== null && e.length < rules[k]) {
            target.error = (i > 0) ? target.error + "," + error.length_less_than + " " + rules[k] : error.length_less_than + " " + rules[k];
            target.valid = false;
          } else {
            target.error = (i > 0) ? target.error : '';
            target.valid = (i > 0) ? target.valid & true : true;
          }
        } else if (k === "maxLength") {
          if (!isNaN(rules[k]) && rules[k] !== null && e.length > rules[k]) {
            target.error = (i > 0) ? target.error + "," + error.length_more_than + " " + rules[k] : error.length_more_than + " " + rules[k];
            target.valid = false;
          } else {
            target.error = (i > 0) ? target.error : '';
            target.valid = (i > 0) ? target.valid & true : true;
          }
        } else if (k === "length") {
          if (!isNaN(rules[k]) && rules[k] !== null && e.length !== rules[k]) {
            target.error = (i > 0) ? target.error + "," + error.length_not_equals.replace("{}", rules[k]) : error.length_not_equals.replace("{}", rules[k]);
            target.valid = false;
          } else {
            target.error = (i > 0) ? target.error : '';
            target.valid = (i > 0) ? target.valid & true : true;
          }
        } else if (k === "birthdate") {
          if (e === "") {
            target.error = (i > 0) ? target.error + "," + error.is_empty : error.is_empty;
            target.valid = false;
          } else {
            target.value = e;
            let age = this.calculateAge(e);
            target.altValue = age;
            if (age[0] < 2) {
              target.error = (i > 0) ? target.error + "," + error.age_too_low : error.age_too_low;
              target.valid = false;
            } else {
              target.valid = true;
              target.error = (i > 0) ? target.error : '';
            }
          }
        }
      });
    }
    target.error = (target.error.charAt(0) === ",") ? target.error.substring(1) : target.error;
    let prevstate = this.state;
    if (arr.length === 1) {
      prevstate.sections[i][statekey] = target;
    } else {
      prevstate.sections[i][arr[0]].commonInput[arr[1]] = target;
    }
    this.setState(prevstate);
    if (!firsttime) this.setCookie(statekey, target);
    return 1;
  }

  validateSingleSelection = (i, rules, target, e, statekey) => {
    let arr = statekey.split("_");
    let prevstate = this.state;
    if (arr.length == 2) {
      Object.keys(prevstate.sections[i][arr[0]].commonInput).map((k, ii) => {
        if (prevstate.sections[i][arr[0]].commonInput[k].type === "RadioSwitch") {
          if (prevstate.sections[i][arr[0]].commonInput[k].id === arr[1]) {
            prevstate.sections[i][arr[0]].commonInput[k].value = 1;
          } else {
            prevstate.sections[i][arr[0]].commonInput[k].value = 0;
          }
        }
      });
      this.setState(prevstate);
    }
  }

  validateMultiValue = (rules, target, e, statekey, fixed = -1) => {
    let chk = -1;
    let out = target.value;
    if (fixed > -1) {
      target.value[fixed] = e;
    } else {
      let out = target.value.map((k, i) => {
        if (k === e) chk = i;
        return k;
      });
      if (chk === -1) {
        out.push(e);
      } else {
        out.splice(chk, 1);
      }
      target.value = out;
    }
    this.setState(target);
    this.setCookie(statekey, target);
    return 1;
  }

  clearQueue = () => {
    cookies.set('queueNumber', undefined);
    this.setState({ queue: false, queueNumber: undefined });
  }

  entering = (e) => {
    e.style.fontFamily = 'Pridi';
  }

  calculateAge = (d) => {
    let birthdate = new Date(d);
    let timenow = new Date();
    let yearAge = (timenow.getYear() - birthdate.getYear());
    let monthAge = (timenow.getMonth() - birthdate.getMonth());
    let dayAge = (timenow.getDate() - birthdate.getDate());
    let daysInMonth = new Date(birthdate.getYear(), birthdate.getMonth() + 1, 0).getDate();
    if (dayAge < 0) {
      dayAge += daysInMonth;
      monthAge -= 1;
    }
    if (monthAge < 0) {
      monthAge += 12;
      yearAge -= 1;
    }
    return [yearAge, monthAge, dayAge];
  }

  calculateBMI = (w, h) => {
    h = h / 100;
    return (w / (h * h)).toFixed(2);
  }

  checkValidation = () => {
    let valid = true;

    if (!valid) {
      this.setState({ validation: false });
    } else {
      this.setState({ validation: true });
      this.setState({ savingprogress: 0, savingstate: true });
    }
  }

  formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  changePage = (id) => {
    if (id == -1) {
      this.setState({ savedstate: false, page: id });
    } else if (id == 1) {
      this.setState(prev => ({ due: prev.section2due, page: id }));
    } else {
      this.setState(prev => ({ due: new Date(), page: id }));
    }
  }

  resetForm = (i, tgt) => {
    this.setState({ section2re: true })
    if (i == -1) {
      if (this.state.section2 == undefined) {
        fetch(endpoint + "a=getlatest&b=bariatric2&d=d0")
          .then(res => res.json())
          .then(data => {
            setTimeout(() => {
              Object.keys(data.formdata[0].val).map((k, i) => {
                if (data.formdata[0].val[k].commonInput != undefined) {
                  Object.keys(data.formdata[0].val[k].commonInput).map((l, j) => {
                    Object.keys(this.state.sections[1]).map((m, a) => {
                      if (this.state.sections[1][m].commonInput != undefined) {
                        Object.keys(this.state.sections[1][m].commonInput).map((n, b) => {
                          if (this.state.sections[1][m].commonInput[n].label == data.formdata[0].val[k].commonInput[l].label) {
                            data.formdata[0].val[k].commonInput[l].value = this.state.sections[1][m].commonInput[n].value;
                          }
                        });
                      }
                    });
                  });
                }
              });
              this.setState(prev => ({ sections: [prev.sections[0], data.formdata[0].val, prev.sections[2]], section2: data.formdata[0].val }));
              parseValidation(1, this.state.sections[1], data.formdata[0].val, this.validateCommonInput);
              this.setState({ section2re: false });
            }, 200);
          });
      } else {
        setTimeout(() => { this.setState(prev => ({ sections: [prev.sections[0], prev.section2, prev.sections[2]], section2re: false })) }, 500);
      }
    } else {
      setTimeout(() => { this.setState(prev => ({ sections: [prev.sections[0], tgt, prev.sections[2]], section2re: false })); }, 500);
    }
  }

  exportForm = () => {
    let outstr = this.state.exptemp;
    let stof = outstr.indexOf("[");
    let enof = outstr.indexOf("]");
    let lim = 100;
    while (stof >= 0 && lim > 0) {
      let tmpstr = outstr.substring(0, stof)
      let wd = outstr.substring(stof + 1, enof);
      tmpstr += this.findElements(wd) + outstr.substring(enof + 1);
      //console.log(lim, tmpstr, wd);
      outstr = tmpstr;
      stof = outstr.indexOf("[");
      enof = outstr.indexOf("]");
      lim -= 1;
    }
    this.setState({ expdata: outstr, exptriger: true });
  }

  findElements = (e) => {
    let out = e + ": ";
    let v = "";
    this.state.sections[2].topicList.map((k, i) => {
      Object.keys(this.state.sections[2][k].commonInput).map((l, j) => {
        if (this.state.sections[2][k].commonInput[l].label == e) {
          v = this.state.sections[2][k].commonInput[l].value;
          if (this.state.sections[2][k].commonInput[l].type === "RadioSwitch") v = (this.state.sections[2][k].commonInput[l].value) ? "YES" : "NO";
        }
      });
    });
    out += v;
    return out;
  }

  uploadFile = (i, statekey, target, fl) => {
    if (fl !== null && fl !== undefined) {
      let fileDat = new FormData();
      fileDat.append("file_upload", fl);
      fetch("https://comedkku.net/projects/mdkku/upload.php?", {
        method: 'POST',
        body: fileDat
      })
        .then(res => res.json())
        .then(data => {
          console.log(data);
          if (data.success == 1) {
            target.value = data.img;
            let arr = statekey.split("_");
            let prevstate = this.state;
            if (arr.length === 1) {
              prevstate.sections[i][statekey] = target;
            } else {
              prevstate.sections[i][arr[0]].commonInput[arr[1]] = target;
            }
            this.setState(prevstate);
            console.log(this.state);
          } else {
            console.log('Upload failed!');
          }
        });
    }
  }

  removeRef = (obj) => {
    let outobj = {};
    if (typeof obj === "string") {
      outobj = obj;
    } else {
      Object.keys(obj).map((k, i) => {
        if (k !== 'ref' && k !== 'section3' && k !== 'section2' &&
          k !== 'section2Due' && k !== 'section2Re' && k !== 'section2His') {
          if (typeof obj[k] === 'object' && obj[k] !== null) {
            if (obj[k].map !== undefined) {
              outobj[k] = obj[k].map((l, j) => { return this.removeRef(l) });
            } else if (obj[k] instanceof Date) {
              outobj[k] = obj[k];
            } else {
              outobj[k] = this.removeRef(obj[k]);
            }
          } else {
            outobj[k] = obj[k];
          }
        }
      });
    }
    return outobj;
  }

  saveData = (p) => {
    let fullname = this.state.sections[0].pfname.value + " " + this.state.sections[0].plname.value;
    let duedate = this.state.due.getFullYear() + "-" + (this.state.due.getMonth() + 1) + "-" + this.state.due.getDate();
    let dat = this.removeRef(this.state);
    let jstr0 = JSON.stringify(dat);
    console.log(jstr0);
    let url = endpoint + "a=set&b=ins&w1=" + fullname + "&w2=" + this.state.uid + "&w3=" + duedate + "&w4=" + this.state.page + "&d=d0&f=data";
    let formdat = "v=" + jstr0;
    this.setState({ savingprogress: 20 });
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
      },
      body: formdat
    })
      .then(res => res.json())
      .then(data => {
        cookies.set('savedstate', true, { path: '/' });
        this.setState({ savingprogress: 100, savingstate: false, savedstate: true });
        if (this.state.page == 2 || this.state.page == 1) {
          window.location.href = '/' + this.state.uid;
        }
      });
  }

  render() {
    let formP = '';
    let formA = '';
    let str_seldate = lang.select_date;
    let readonly = false;
    if (this.state.sections[this.state.page] !== undefined) {
      formA = this.state.sections[this.state.page].topicList.map((k, i) => {
        let c = (i % 2 === 1) ? "odd" : "even";
        let radiovalidate = (this.state.sections[this.state.page][k].single) ? this.validateSingleSelection : this.validateCommonInput;
        let ele = Object.keys(this.state.sections[this.state.page][k].commonInput).map((l, j) => {
          let eleout = [];
          let w = true;
          if (this.state.sections[this.state.page][k].commonInput[l].with !== undefined && this.state.sections[this.state.page][k].commonInput[l].with !== "") {
            let arr = this.state.sections[this.state.page][k].commonInput[l].with.split("_");
            if (arr.length > 1) {
              w = this.state.sections[this.state.page][arr[0]].commonInput[arr[1]].value;
            }
          }
          if (this.state.sections[this.state.page][k].commonInput[l].without !== undefined && this.state.sections[this.state.page][k].commonInput[l].without !== "") {
            let arr = this.state.sections[this.state.page][k].commonInput[l].without.split("_");
            if (arr.length > 1) {
              w = w & !this.state.sections[this.state.page][arr[0]].commonInput[arr[1]].value;
            }
          }
          if (this.state.sections[this.state.page][k].commonInput[l].type === "RadioSwitch") {
            eleout = <div className="d-flex"><RadioSwitch section={this.state.page} statekey={k + "_" + l} validation={radiovalidate} target={this.state.sections[this.state.page][k].commonInput[l]} show={w} /></div>;
          } else if (this.state.sections[this.state.page][k].commonInput[l].type === "TextInput") {
            eleout = <div className="d-flex"><InputValidation section={this.state.page} type="text" statekey={k + "_" + l} validation={this.validateCommonInput} target={this.state.sections[this.state.page][k].commonInput[l]} show={w} /></div>;
          } else if (this.state.sections[this.state.page][k].commonInput[l].type === "NumberInput") {
            eleout = <div className="d-flex"><InputValidation section={this.state.page} type="number" statekey={k + "_" + l} validation={this.validateCommonInput} target={this.state.sections[this.state.page][k].commonInput[l]} show={w} /></div>;
          } else if (this.state.sections[this.state.page][k].commonInput[l].type === "OptionalTextInput") {
            eleout = <div className="d-flex"><InputOptional section={this.state.page} type="text" statekey={k + "_" + l} validation={this.validateCommonInput} target={this.state.sections[this.state.page][k].commonInput[l]} show={w} /></div>;
          } else if (this.state.sections[this.state.page][k].commonInput[l].type === "OptionalNumberInput") {
            eleout = <div className="d-flex"><InputOptional section={this.state.page} type="number" statekey={k + "_" + l} validation={this.validateCommonInput} target={this.state.sections[this.state.page][k].commonInput[l]} show={w} /></div>;
          } else if (this.state.sections[this.state.page][k].commonInput[l].type === "FileInput") {
            eleout = <div className="d-flex"><InputUpload section={this.state.page} type="file" statekey={k + "_" + l} handler={this.uploadFile} target={this.state.sections[this.state.page][k].commonInput[l]} show={w} /></div>;
          } else if (this.state.sections[this.state.page][k].commonInput[l].type === "SelectValue") {
            eleout = <div className="d-flex"><SelectionValue section={this.state.page} statekey={k + "_" + l} validation={this.validateCommonInput} target={this.state.sections[this.state.page][k].commonInput[l]} show={w} /></div>;
          }
          return (eleout);
        });
        return (
          <div className={"mt-2 " + c + "-row"}>
            <h4 className="mt-2 mb-0">{this.state.sections[this.state.page][k].title}</h4>
            {ele}
          </div>
        );
      });
      let folDetail = this.state.section3.reverse().map((k, i) => {
        let tp = k.topicList.map((l, i) => {
          return (
            Object.keys(k[l].commonInput).map((m, c) => {
              return (
                <Stack direction='horizontal' gap={2}>
                  <div>{k[l].commonInput[m].label}</div>
                  <div>{k[l].commonInput[m].value}</div>
                </Stack>
              );
            })
          );
        });
        return (
          <Accordion.Item eventKey={i}>
            <Accordion.Header>{new Date(k.due).toString().slice(0, new Date(k.due).toString().indexOf("GMT"))}</Accordion.Header>
            <Accordion.Body>
              {tp}
            </Accordion.Body>
          </Accordion.Item>
        );
      });
      let s2hisdd = (this.state.page == 1 && this.state.section2His.length > 0) ? (
        <Dropdown className='me-1'>
          <Dropdown.Toggle variant='secondary' id='s2hisdd' style={{ fontSize: '0.9em' }}>Logs</Dropdown.Toggle>
          <Dropdown.Menu>{this.state.section2His.map((k, i) => { return (<Dropdown.Item onClick={(e) => { this.resetForm(i, k.val) }} style={{ fontSize: '0.75em' }}>modified: {new Date(k.tm * 1000).toDateString()}</Dropdown.Item>) })}</Dropdown.Menu>
        </Dropdown>
      ) : [];
      let reico = (this.state.section2re) ? <Spinner size="sm" animation="border" variant="light" show={false} /> : <i className='fa fa-repeat'></i>;
      let rebtn = (this.state.page == 1) ? <Button disabled={this.state.section2re} onClick={(e) => { this.resetForm(-1, undefined) }} variant='secondary' style={{ fontSize: '0.9em' }}>{reico} RE</Button> : [];
      let expbtn = (this.state.page == 2) ? [
        <Button onClick={(e) => { this.exportForm() }} variant='secondary' style={{ fontSize: '0.9em' }}>Export</Button>,
        <Button onClick={(e) => { this.setState({ temptriger: true }) }} variant='secondary' style={{ marginLeft: '5px', fontSize: '0.9em' }}>Template</Button>
      ] : [];
      formP = [
        <div>
          <Stack direction='horizontal'><h2 className="hd-mask me-auto">{(this.state.page == 1) ? "Operative Day" : "Follow-Up"}</h2>{[s2hisdd, rebtn, expbtn]}</Stack>
          {(this.state.page == 2 && folDetail.length > 0) ? <Accordion defaultActiveKey="0">{folDetail}</Accordion> : []}
          {formA}
          <SelectionDateTime target={{ label: field.select_duedate, value: this.state.due, ref: React.createRef() }} handler={this.setDue} />
          <div className="d-grid mt-3">
            <Alert variant="danger" show={!this.state.validation} onClose={(e) => { this.setState({ validation: true }) }} dismissible>
              <Alert.Heading></Alert.Heading>
              {lang.please_validation}
            </Alert>
            <Alert variant="success" show={this.state.validation && this.state.savedstate} onClose={(e) => { this.setState({ validation: true }) }} dismissible>
              <Alert.Heading></Alert.Heading>
              {lang.save_succesful}
            </Alert>
            <Button variant="success" className="rounded-pill" size="lg" onClick={(e) => { this.checkValidation() }}>{lang.confirm}</Button>
          </div>
          <OffcanvasProgressTop handler={this.saveData} show={this.state.savingstate} value={this.state.savingprogress} title={lang.saving} />
        </div>
      ];
      str_seldate = lang.your_select_date;
      readonly = true;
    }
    let chkO = (this.state.chkOperative) ? <i className='fa fa-check-square-o fs-4 hover-icon'></i> : [];
    let chkF = (this.state.chkFollow > 0) ? <i className='fa fa-check-square-o fs-4 hover-icon'><span style={{ fontSize: '0.5em' }}>{this.state.chkFollow}</span></i> : [];
    let landingBtns = [
      <div className="mx-auto landingBtns text-center">
        <Button variant="success" className='rounded m-2' onClick={(e) => { window.location.href = '/dr' }}><i className='fa fa-home'></i><br />Home</Button><br />
        <Button variant="warning" className='rounded m-2' onClick={(e) => { this.changePage(1) }}>{chkO}<i className='fa fa-bed'></i><br />Operative</Button>
        <Button variant="info" className='rounded m-2' onClick={(e) => { this.changePage(2) }}>{chkF}<i className='fa fa-user-md'></i><br />Follow-up</Button>
      </div>
    ];

    let men1 = (this.state.loading) ? [
      <Container className="p-5 text-center">
        <Button variant="primary" className="" size="lg" disabled>
          <Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />
        </Button>
      </Container>
    ] : [
      <Container fluid="sm" className="pt-5 pb-5 p-lg-5 pe-3">
        {(this.state.page > -1) ? formP : landingBtns}
      </Container>
    ];

    const popCP = (
      <Popover id='popover-copy-clipboard' title='Popover right'><strong>coppied!</strong></Popover>
    );

    return (
      <div className="App">
        <header className="App-header">
          <Navbarmod lang={lang} logo={logo} />
          {(!this.state.loading && this.state.page >= 0) ? <SidebarCollapse handler={this.changePage} data={this.state} /> : []}
          {(this.state.page == 2) ? [<Offcanvas placement='bottom' show={this.state.exptriger} onHide={(e) => { this.setState({ exptriger: false }) }} style={{ marginTop: '101px', paddingLeft: '50px' }}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <Stack direction='horizontal' gap={3}>
                  <div className='me-auto'>Note</div>
                  <div className="vr" />
                  <OverlayTrigger rootClose trigger="click" placement="right" overlay={popCP}>
                    <Button variant='secondary' onClick={(e) => { navigator.clipboard.writeText(this.state.expdata) }}><i className='fa fa-clipboard'></i></Button>
                  </OverlayTrigger>
                </Stack>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <textarea className='w-100' style={{ height: '200px' }}>{this.state.expdata}</textarea>
            </Offcanvas.Body>
          </Offcanvas>,
          <Offcanvas placement='bottom' show={this.state.temptriger} onHide={(e) => { this.setState({ temptriger: false }) }} style={{ marginTop: '101px', paddingLeft: '50px' }}>
            <Offcanvas.Header closeButton><Offcanvas.Title>Template</Offcanvas.Title></Offcanvas.Header>
            <Offcanvas.Body>
              <textarea className='w-100' style={{ height: '200px' }} onChange={(e) => { this.setState({ exptemp: e.target.value }) }}>{this.state.exptemp}</textarea>
            </Offcanvas.Body>
          </Offcanvas>] : []}
          {men1}
        </header>
        <Footer lang={lang} />
      </div>
    );
  }
}

export default App;
