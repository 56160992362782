import React from 'react';
import { Router, Route } from 'react-router';

import App from './components/App';
import App2 from './components/App2';
import App3 from './components/App3';
import App4 from './components/App4';

const Routes = (props) => (
	<Router {...props}>
		<Route path="/" component={App}/>
		<Route path="/editor" component={App2}/>
		<Route path="/editor/:table" component={App2}/>
		<Route path="/dr" component={App3}/>
		<Route path="/:uid" component={App4}/>
	</Router>
);

export default Routes;
