import React, { useState } from 'react';
import { Navbar, Container, Nav, Offcanvas, Overlay, Popover, Form, ProgressBar, NavDropdown, InputGroup } from 'react-bootstrap';


import { lang, error } from './config.lang-dev';

const entering = (e) => {
  e.style.fontFamily = 'Pridi';
}

const addrules = (t,target,e) => {
  let old = target.rules;
  if(old===undefined){
    old = {};
  }
  old[t] = e;
  return old;
}

const addOptions = (target) => {
  let old = target.options;
  if(old===undefined){
    old = [{value:0,label:"Undefined"}];
  }else{
    old.push({value:old.length,label:"Undefined"});
  }
  return old;
}

const editOptions = (target,v,e) => {
  let out = target.options.map((k,i)=>{
    if(k.value===v){
      return {value:k.value,label:e};
    }else{
      return k;
    }
  });
  return out;
}

const removeOptions = (target,v) => {
  let out = [];
  target.options.map((k,i)=>{
    if(k.value!==v){
      out.push(k);
    }
  });
  return out;
}

export const Navbarmod = (props) => {
  let addIcon = (<i className="bi bi-plus-circle-fill fs-2"></i>);
	return(
      <Navbar sticky="top" className="bg-opacity-50 shadow nav" variant="light">
        <Container fluid>
          <Navbar.Brand href="#" className="d-flex align-items-center">
            <img src={props.logo} height="75" className="d-inline-block align-top" alt="logo" />
            <div className="d-block ms-3">
              <h4 className="mt-1 site-title">{props.lang.site_title}</h4>
              <div className="d-flex justify-content-center">
                <div className="hr-line"></div>
                <div className="hr-icon">{props.lang.site_title_sep}</div>
                <div className="hr-line"></div>
              </div>
              <h6 className="text-center site-subtitle">{props.lang.site_subtitle}</h6>
            </div>
          </Navbar.Brand>
          <Nav className="d-flex justify-content-end flex-grow-1 pe-3">
            <NavDropdown title={addIcon} id="add-dropdown" align="end">
              <NavDropdown.Item href="#" onClick={(e)=>{props.addHandler('','')}}>{lang.questionare.topic_box}</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Container>
      </Navbar>
	);
}

export const NavbarDyn = (props) => {
  let outbtn = [];
  if(props.state!=undefined){
    outbtn = <Nav.Link id="sign-out" align="end" href="#" onClick={(e)=>{props.signout(props.cookies,props.state)}}>Sign Out</Nav.Link>;
  }
	return(
      <Navbar sticky="top" className="bg-opacity-50 shadow nav" variant="light">
        <Container fluid>
          <Navbar.Brand href="#" className="d-flex align-items-center">
            <img src={props.logo} height="75" className="d-inline-block align-top" alt="logo" />
            <div className="d-block ms-3">
              <h4 className="mt-1 site-title">{props.lang.site_title}</h4>
              <div className="d-flex justify-content-center">
                <div className="hr-line"></div>
                <div className="hr-icon">{props.lang.site_title_sep}</div>
                <div className="hr-line"></div>
              </div>
              <h6 className="text-center site-subtitle">{props.lang.site_subtitle}</h6>
            </div>
          </Navbar.Brand>
          <Nav className="d-flex justify-content-end flex-grow-1 pe-3">
            {outbtn}
          </Nav>
        </Container>
      </Navbar>
	);
}

export const SidebarCollapse = (props) => {
  return(
    <Nav className="fixed-top border text-secondary fs-6 rounded sidebar-nav" style={{marginTop:'101px'}}>
      <Nav.Item>
        <Nav.Link className="text-secondary" href="/editor"><i className="bi bi-person-lines-fill fs-4"></i></Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link className="text-secondary" href="/editor/bariatric2"><i className="bi bi-eye-fill fs-4"></i></Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link className="text-secondary" href="/editor/bariatric3"><i className="bi bi-journal-check fs-4"></i></Nav.Link>
      </Nav.Item>
    </Nav>
  );
}

export const Footer = (props) => {
	return(
	    <Container className="p-4 text-center shadow site-footer">
	      <span>{props.lang.footer_affiliation}</span><br/>
	      <span><i className="bi bi-envelope-fill"></i>&nbsp;{props.lang.footer_mail}&nbsp;&nbsp;&nbsp;&nbsp;<i className="bi bi-telephone-fill"></i>&nbsp;{props.lang.footer_phone}</span>
	    </Container>
	);
}

export const OffcanvasProgressTop = (props) => {
  return(
    <Offcanvas show={props.show} placement="bottom" onEntered={(e)=>{props.handler(-1)}}>
      <Offcanvas.Header>
        <Offcanvas.Title>{props.title}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ProgressBar animated now={props.value}/>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export const RadioSwitchDev = (props) => {
  let opts = props.sibling.map((k,i)=>{
    return (props.target.with!==undefined&&k.key===props.target.with)?
    <option selected key={k.key} value={k.key}>{k.ref}</option>:
    <option key={k.key} value={k.key}>{k.ref}</option>;
  });
  let opts2 = props.sibling.map((k,i)=>{
    return (props.target.without!==undefined&&k.key===props.target.without)?
    <option selected key={k.key} value={k.key}>{k.ref}</option>:
    <option key={k.key} value={k.key}>{k.ref}</option>;
  });
  let swith = (
    <Form.Select onChange={(e) => { props.target.with = e.target.value; props.handler(props.topic,props.target.id,props.target);}}>
      <option value="">{lang.questionare.select_from_list}</option>
      {opts}
    </Form.Select>
  );
  let swithout = (
    <Form.Select onChange={(e) => { props.target.without = e.target.value; props.handler(props.topic,props.target.id,props.target);}}>
      <option value="">{lang.questionare.select_from_list}</option>
      {opts2}
    </Form.Select>
  );
  let formctrl = [
    <div className="d-md-flex">
      <Form.Control type="text" label={lang.formlabel} value={props.target.label} onChange={(e) => { props.target.label = e.target.value; props.handler(props.topic,props.target.id,props.target);}} />
      <InputGroup className="ms-md-1">
        <InputGroup.Text>{lang.questionare.show_with}</InputGroup.Text>
        {swith}
      </InputGroup>
      <InputGroup className="ms-md-1">
        <InputGroup.Text>{lang.questionare.show_without}</InputGroup.Text>
        {swithout}
      </InputGroup>
    </div>
  ];
  return (
    <div className="mt-2">
      <Form.Group className="d-flex">
        <Form.Check
          type="switch"
          id={props.topic+'-'+props.target.id}
          label={props.target.label}
        />
        &nbsp;<i className="bi bi-pencil-square" role="button" onClick={(e)=>{props.editHandler(props.topic,props.target.id)}}></i>
        &nbsp;<i className="bi bi-dash-square" role="button" onClick={(e)=>{props.removeHandler(props.topic,props.target.id)}}></i>
      </Form.Group>
      <Form.Group>
        {(props.edit)?formctrl:[]}
      </Form.Group>
    </div>
  );
}

export const InputValidationDev = (props) => {
  let opts = props.sibling.map((k,i)=>{
    return (props.target.with!==undefined&&k.key===props.target.with)?
    <option selected key={k.key} value={k.key}>{k.ref}</option>:
    <option key={k.key} value={k.key}>{k.ref}</option>;
  });
  let opts2 = props.sibling.map((k,i)=>{
    return (props.target.without!==undefined&&k.key===props.target.without)?
    <option selected key={k.key} value={k.key}>{k.ref}</option>:
    <option key={k.key} value={k.key}>{k.ref}</option>;
  });
  let swith = (
    <Form.Select onChange={(e) => { props.target.with = e.target.value; props.handler(props.topic,props.target.id,props.target);}}>
      <option value="">{lang.questionare.select_from_list}</option>
      {opts}
    </Form.Select>
  );
  let swithout = (
    <Form.Select onChange={(e) => { props.target.without = e.target.value; props.handler(props.topic,props.target.id,props.target);}}>
      <option value="">{lang.questionare.select_from_list}</option>
      {opts2}
    </Form.Select>
  );
  let formctrl0 = [
    <div className="d-md-flex">
      <InputGroup className="ms-md-1">
        <InputGroup.Text>{lang.questionare.show_with}</InputGroup.Text>
        {swith}
      </InputGroup>
      <InputGroup className="ms-md-1">
        <InputGroup.Text>{lang.questionare.show_without}</InputGroup.Text>
        {swithout}
      </InputGroup>
    </div>
  ];
  let formctrl = (props.type==="text")?[
    <div className='d-md-flex'>
    <Form.Control type="text" label={lang.formlabel} value={props.target.label} onChange={(e) => { props.target.label = e.target.value; props.handler(props.topic,props.target.id,props.target);}} />
    <InputGroup className="ms-1">
      <InputGroup.Text>{lang.questionare.length}</InputGroup.Text>
      <Form.Control type="number" value={(props.target.rules!==undefined&&props.target.rules.length!==undefined)?props.target.rules.length:''} onChange={(e) => {props.target.rules = addrules('length',props.target,parseInt(e.target.value)); props.handler(props.topic,props.target.id,props.target);}} />
    </InputGroup>
    <InputGroup className="ms-3">
      <Form.Check className="text-nowrap mt-2" type="switch" id={props.topic+'-'+props.target.id+'-check'} checked={(props.target.rules!==undefined)?props.target.rules.notEmpty:false} label={lang.questionare.not_empty} onChange={(e) => {props.target.rules = addrules('notEmpty',props.target,e.target.checked); props.handler(props.topic,props.target.id,props.target);}} />
    </InputGroup>
    <InputGroup className="ms-3">
      <Form.Check className="text-nowrap mt-2" type="switch" id={props.topic+'-'+props.target.id+'-checkopt'} checked={(props.target.type==='OptionalTextInput')} label={lang.questionare.optional_input} onChange={(e) => {props.target.type=(e.target.checked)?"OptionalTextInput":"TextInput"; props.handler(props.topic,props.target.id,props.target);}} />
    </InputGroup>
  </div>
  ]:[
    <div className='d-md-flex'>
    <Form.Control type="text" label={lang.formlabel} value={props.target.label} onChange={(e) => { props.target.label = e.target.value; props.handler(props.topic,props.target.id,props.target);}} />
    <InputGroup className="ms-1">
      <InputGroup.Text>{lang.questionare.min_number}</InputGroup.Text>
      <Form.Control type="number" value={(props.target.rules!==undefined&&props.target.rules.minNumber!==undefined)?props.target.rules.minNumber:''} onChange={(e) => {props.target.rules = addrules('minNumber',props.target,parseInt(e.target.value));props.target.rules = addrules('type',props.target,'digit'); props.handler(props.topic,props.target.id,props.target);}} />
    </InputGroup>
    <InputGroup className="ms-1">
      <InputGroup.Text>{lang.questionare.max_number}</InputGroup.Text>
      <Form.Control type="number" value={(props.target.rules!==undefined&&props.target.rules.maxNumber!==undefined)?props.target.rules.maxNumber:''} onChange={(e) => {props.target.rules = addrules('maxNumber',props.target,parseInt(e.target.value));props.target.rules = addrules('type',props.target,'digit'); props.handler(props.topic,props.target.id,props.target);}} />
    </InputGroup>
    <InputGroup className="ms-3">
      <Form.Check className="text-nowrap mt-2" type="switch" id={props.topic+'-'+props.target.id+'-checkopt'} checked={(props.target.type==='OptionalNumberInput')} label={lang.questionare.optional_input} onChange={(e) => {props.target.type=(e.target.checked)?"OptionalTextInput":"NumberInput"; props.handler(props.topic,props.target.id,props.target);}} />
    </InputGroup>
  </div>
  ];
  return (
    <div className="mt-2">
      <Form.Group className="mb-2">
        <Form.Label>
          {props.target.label}
          &nbsp;<i className="bi bi-pencil-square" role="button" onClick={(e)=>{props.editHandler(props.topic,props.target.id)}}></i>
          &nbsp;<i className="bi bi-dash-square" role="button" onClick={(e)=>{props.removeHandler(props.topic,props.target.id)}}></i>
          {(props.edit)?[formctrl,formctrl0]:[]}
        </Form.Label>
        <Form.Control type={props.type} label={props.target.label} onFocus={(e)=>{e.target.select()}} readOnly={true}></Form.Control>
      </Form.Group>
    </div>
  );
}

export const InputUploadDev = (props) => {
  let swith = (
    <Form.Select onChange={(e) => { props.target.with = e.target.value; props.handler(props.topic,props.target.id,props.target);}}>
      <option value="">{lang.questionare.select_from_list}</option>
      {props.sibling}
    </Form.Select>
  );
  let swithout = (
    <Form.Select onChange={(e) => { props.target.without = e.target.value; props.handler(props.topic,props.target.id,props.target);}}>
      <option value="">{lang.questionare.select_from_list}</option>
      {props.sibling}
    </Form.Select>
  );
  let formctrl0 = [
    <div className="d-md-flex">
      <InputGroup className="ms-md-1">
        <InputGroup.Text>{lang.questionare.show_with}</InputGroup.Text>
        {swith}
      </InputGroup>
      <InputGroup className="ms-md-1">
        <InputGroup.Text>{lang.questionare.show_without}</InputGroup.Text>
        {swithout}
      </InputGroup>
    </div>
  ];
  let formctrl = (
  <div className='d-md-flex'>
    <Form.Control type="text" label={lang.formlabel} value={props.target.label} onChange={(e) => { props.target.label = e.target.value; props.handler(props.topic,props.target.id,props.target);}} />
    <InputGroup className="ms-1">
      <InputGroup.Text>{lang.questionare.max_size}</InputGroup.Text>
      <Form.Control type="number" onChange={(e) => {}} />
    </InputGroup>
  </div>
  );
  return (
    <div className="mt-2">
      <Form.Group className="mb-2">
        <Form.Label>
          {props.target.label}
          &nbsp;<i className="bi bi-pencil-square" role="button" onClick={(e)=>{props.editHandler(props.topic,props.target.id)}}></i>
          &nbsp;<i className="bi bi-dash-square" role="button" onClick={(e)=>{props.removeHandler(props.topic,props.target.id)}}></i>
          {(props.edit)?[formctrl,formctrl0]:[]}
        </Form.Label>
        <Form.Control type={props.type} label={props.target.label} onFocus={(e)=>{e.target.select()}}></Form.Control>
      </Form.Group>
    </div>
  );
}

export const SelectionValueDev = (props) => {
  let formctrl = (
  <div className='d-md-flex'>
    <Form.Control type="text" label={lang.formlabel} value={props.target.label} onChange={(e) => { props.target.label = e.target.value; props.handler(props.topic,props.target.id,props.target);}} />
  </div>
  );
  let out = [];
  if(props.target.options!==undefined&&props.target.options.length>0){
    out = props.target.options.map((k,i)=>{
    return(
      <div>
        <Form.Check inline id={props.target.label+'_'+i} type="radio" value={k.value} label={k.label}/>
        <Form.Control type="text" label={lang.formlabel} value={k.label} onChange={(e) => { props.target.options = editOptions(props.target,k.value,e.target.value); props.handler(props.topic,props.target.id,props.target);}} />
        &nbsp;<i className="bi bi-dash-circle" role="button" onClick={(e)=>{ props.target.options = removeOptions(props.target,k.value); props.handler(props.topic,props.target.id,props.target);}}></i>
      </div>
      );
    });
  }
  out.push(
    <i className="bi bi-plus-circle" role="button" onClick={(e)=>{ props.target.options = addOptions(props.target); props.handler(props.topic,props.target.id,props.target);}}></i>
  );
  return(
    <Form.Group className="mb-1">
      <Form.Label className="me-4">
        {props.target.label}
        &nbsp;<i className="bi bi-pencil-square" role="button" onClick={(e)=>{props.editHandler(props.topic,props.target.id)}}></i>
        &nbsp;<i className="bi bi-dash-square" role="button" onClick={(e)=>{props.removeHandler(props.topic,props.target.id)}}></i>
        {(props.edit)?[formctrl]:[]}
      </Form.Label>
      {out}
    </Form.Group>
  );
}