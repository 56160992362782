export const lang = {
	site_title: "Bariatric",
	site_subtitle: "Questionare",
	site_title_sep: "*",
	footer_affiliation: "Faculty of Medicine, Khon Kaen University",
	footer_mail: "-",
	footer_phone: "-",
	queue: "ลงทะเบียน",
	select_date:"กรุณาเลือกวันที่เข้าพบแพทย์",
	your_select_date:"วันที่เข้าพบแพทย์",
	your_queue: "ลำดับที่",
	cancel_queue: "(กดปุ่มเพื่อยกเลิก)",
	year: "ปี",
	month: "เดือน",
	day: "วัน",
	yes_no_opt:["ใช่","ไม่ใช่"],
	other: "อื่น ๆ",
	confirm: "ยืนยัน",
	saving: "กำลังบันทึก",
	please_validation: "กรุณาตรวจสอบข้อมูลให้ถูกต้อง",
	save_succesful: "บันทึกข้อมูลสำเร็จ",
}

export const field = {
	pfname: "ชื่อ",
	plname: "นามสกุล",
	pcid: "รหัสประจำตัวประชาชน",
	pbirth: "วัน/เดือน/ปี เกิด",
	page: "อายุ",
	pyear: "ปี",
	pmonth: "เดือน",
	pday: "วัน",
	pweight: "น้ำหนัก (kg)",
	pheight: "ส่วนสูง (cm)",
	pbmi: "BMI",
	dietpill: "เคยทานยาลดน้ำหนัก",
	dietfood: "ลดน้ำหนักโดยวิธีควบคุมอาหาร",
	dietfoodterm: "ระยะเวลาในการควบคุมอาหาร (สัปดาห์)",
	walkrun: "ออกกำลังกายโดยการวิ่งหรือเดิน",
	walkrunless: "นาน ๆ ที",
	walkrunfreq: "จำนวนครั้ง/สัปดาห์",
	eatbehavior: "พฤติกรรมการกิน",
	eatsweetjuice: "ทานน้ำหวาน",
	eatbuffet: "ทานบุฟเฟต์",
	eatbekery: "ทานเบเกอรี่",
	congenital: "โรคประจำตัว",
	diabetes: "เบาหวาน",
	diabetesdrug: "ยาเบาหวาน",
	hypertension: "ความดัน",
	hypertensiondrug: "ยาความดัน",
	snoring: "นอนกรน",
	pcos: "PCOS",
	birthcontrolpill: "ยาคุมกำเนิด",
	kneepain: "ปวดเข่า",
	backache: "ปวดหลัง",
	cholesterol: "ไขมันสูง",
	cholesterolpill: "ยาลดไขมัน",
	othercongenital: "โรคประจำตัว อื่น ๆ (ระบุ)",
	hadsurgery: "เคยผ่าตัด",
	hadabdomensurgery: "เคยผ่าตัดช่องท้อง",
	hadanesthasia: "เคยดมยาสลบ",
	anesthasiaproblem: "มีปัญหาการดมยาสลบ",
	indigestion: "มีอาการแสบร้อนกลางอก",
	heartburn: "มีอาการจุกแน่นท้องหลังทานอาหาร",
	stomachache: "มีอาการปวดท้องเรื้อรัง",
	stomachacheadmit: "เคยไป รพ. ด้วยอาการปวดท้อง",
	gallstones: "เคยตรวจพบนิ่วในถุงน้ำดี",
	menstruationstable: "ประจำเดือนมาสม่ำเสมอ",
	firstmenstruation: "มีประจำเดือนตอนอายุ",
	children: "มีบุตร",
	childrennumber: "จำนวน",
	boysnumber: "ชาย",
	girlsnumber: "หญิง",
	hadbirthcontrolpill: "เคยทานยาคุมกำเนิด",
	birthcontrolpillduration: "ระยะเวลาใช้ยานาน (เดือน)",
	birthcontrol: "คุมกำเนิด",
	condomforbirthcontrol: "ถุงยางอนามัย",
	pillforbirthcontrol: "ยาคุมกำเนิด",
	injectforbirthcontrol: "ฉีดยา",
	naturalforbirthcontrol: "ธรรมชาติ หรือ นับวัน",
	havebabies: "ตั้งใจมีบุตร",
	havebabiesin: "ภายในกี่เดือน",
	constipation: "ท้องผูก",
	defecateduration: "ถ่ายกี่วันครั้ง",
	skinproblem: "ปัญหาผิวหนัง",
	skinprobleminlegs: "แผลบริเวณขาเรื้อรัง",
	skinproblemingroin: "ปัญหาผิวหนังบริเวณขาหนีบ",
	hardsnoring: "นอนกรนเสียงดัง",
	dozeoff: "เหนื่อยอ่อนเพลียตอนกลางวัน หรือเคยหลับใน",
	hadsleepapnea: "มีคนสังเกตว่าหยุดหายใจขณะหลับ",
	hadhypertension: "มีความดันโลหิตสูง",
	neckrounded: "เส้นรอบคอ (เซนติเมตร)",
	test_opt: ["choice1","choice2","choice3"],
	pgender: "เพศ",
	gender_opt: [{label:"ชาย",value:'0'},{label:"หญิง",value:'1'}],
	select_duedate: "Submit Date",
}

export const error = {
	too_long_cid: "รหัสประจำตัวประชาชนเกิน 13 หลัก",
	too_short_cid: "รหัสประจำตัวประชาชนไม่ถึง 13 หลัก",
	not_a_number: "รหัสประจำตัวประชาชนต้องมีเฉพาะตัวเลข",
	is_empty: "ช่องนี้ต้องไม่เป็นค่าว่าง",
	age_too_low: "กรุณาตรวจสอบ วัน/เดือน/ปี เกิด",
	is_zero: "ช่องนี้ต้องไม่เป็นศูนย์",
	is_not_less_than: "ค่าต้องไม่ต่ำกว่า",
	is_not_more_than: "ค่าต้องไม่เกิน",
	length_less_than: "ความยาวต้องไม่น้อยกว่า",
	length_more_than: "ความยาวมากเกินกว่า",
	length_not_equals: "ต้องมีความยาวเท่ากับ {} หลัก",
}

export const units = {
	bmi: [<span>kg/m<sup>2</sup></span>],
}

export const cmonths = ["มกราคม","กุมภาพันธ์","มีนาคม","เมษายน","พฤษภาคม","มิถุนายน","กรกฎาคม","สิงหาคม","กันยายน","ตุลาคม","พศจิกายน","ธันวาคม"];