import React, { Component } from 'react';
import { Container, Button, Form, NavDropdown, Spinner, Stack, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import Cookies from 'universal-cookie';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import bgHead from './bg.png';
import logo from './logo.png';
import './App.css';
import { NavbarDyn, Footer, OffcanvasProgressTop } from './react-mdkku-ui-questionare-dev';

import { lang } from './config.lang-doc';
import { endpoint } from './config.env';

const cookies = new Cookies();

//const endpoint = "https://comedkku.net/nosql/api4all.php?";
//const endpoint = "http://localhost/nosql/api4all.php?";

const hash = (str) => {

}

const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;
  return [year, month, day].join('-');
}

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      date: new Date(),
      logingin: false,
      loading: true,
      user: cookies.get('user'),
      iusr: '',
      usrref: React.createRef(),
      ipwd: '',
      pwdref: React.createRef(),
      list: [],
      listFilter: [],
      txtfilter: '',
    }
  }

  componentDidMount() {
    fetch(endpoint + "a=getalldistinct&b=ins&w=" + formatDate(this.state.date))
      .then(res => res.json())
      .then((data) => {
        this.setState({ list: data, loading: false });
        console.log(this.state);
        if (cookies.get('filter') !== undefined) {
          console.log("filter", cookies.get('filter'));
          this.filterList(cookies.get('filter'));
        }
      });
  }

  signin() {
    this.setState({ logingin: true });
    fetch(endpoint + "a=gethash&b=user&d=d0&w=" + this.state.iusr + "&w1=" + this.state.ipwd)
      .then(res => res.json())
      .then((data) => {
        this.state.usrref.current.value = '';
        this.state.pwdref.current.value = '';
        if (data.length == 0) {
          alert("Invalid Username or Password!");
          this.setState({ logingin: false });
        } else {
          alert(data['profile'][0]['val']['username']);
          cookies.set('user', data['profile'][0]['val']['username'], { path: '/' });
          this.setState({ "user": data['profile'][0]['val']['username'], logingin: false });
        }
        //console.log(data);
      });
  }

  signout = (c, s) => {
    //console.log(c, s);
    c.set('user', undefined);
    this.setState({ "user": undefined });
  }

  selectDate = (e) => {
    this.setState({ date: e });
    console.log(endpoint + "a=getall&b=ins&w=" + formatDate(e));
    fetch(endpoint + "a=getall&b=ins&w=" + formatDate(e))
      .then(res => res.json())
      .then((data) => {
        this.setState({ list: data });
        //console.log(this.state);
      });
  }

  filterList(e) {
    console.log(e);
    if (e.length > 0) {
      let tmp = this.state.list;
      for (let i = 0; i < e.length; i++) {
        if (e[i] == 1) {
          tmp = tmp.filter(x => x.c0 > 0);
        } else if (e[i] == 2) {
          tmp = tmp.filter(x => x.c1 > 0);
        }
      }
      this.setState({ listFilter: tmp });
      cookies.set('filter', e, { path: '/' });
    } else {
      this.setState({ listFilter: [] });
      cookies.set('filter', [], { path: '/' });
    }
  }

  render() {
    let calendar = <>
      <DatePicker selected={this.state.date} onSelect={(e) => { this.selectDate(e) }} dateFormat="dd/MM/yyyy" customInput={<i className="bi bi-calendar3 fs-2"></i>} />
    </>;
    let tmp = (this.state.listFilter.length > 0 || (cookies.get('filter') !== undefined && cookies.get('filter').length > 0)) ? this.state.listFilter : this.state.list;
    if (this.state.txtfilter !== '') tmp = tmp.filter(x => x.w1.includes(this.state.txtfilter));
    let listData = tmp.map((k, i) => {
      let cls = (i % 2 === 1) ? "even" : "odd";
      let chkO = (k.c0 > 0) ? <i className='fa fa-bed fs-4'></i> : [];
      let chkF = (k.c1 > 0) ? <i className='fa fa-user-md fs-4'><span style={{ fontSize: '0.5em' }}>{k.c1}</span></i> : [];
      return (
        <a href={"/" + k.uid} className="font-monospace text-decoration-none">
          <div class={cls + "-row mt-2 pt-1"}>
            <div className={"col"}>{k.w1}</div>
            <div className={"col"}>
              <Stack direction='horizontal' gap={2}>
                <div className="me-auto">{k.w0}</div>
                <div>{chkO}</div>
                <div>{chkF}</div>
              </Stack>
            </div>
          </div>
        </a>
      );
    });
    let loging = (this.state.logingin) ? <Spinner size="sm" animation="border" variant="light" show={false} /> : [];
    let mainout = (<>
      <NavbarDyn lang={lang} logo={logo} />
      <div class='login-pan'>
        <h3>Sign In</h3>
        <div class='input-items'>
          <span>Username:</span><input type='text' name='username' ref={this.state.usrref} onChange={(e) => { this.setState({ 'iusr': e.target.value }); }} />
          <span>Password:</span><input type='password' name='password' ref={this.state.pwdref} onChange={(e) => { this.setState({ 'ipwd': e.target.value }); }} />
          <Button variant="secondary" disabled={this.state.logingin} onClick={(e) => { this.signin(); }} style={{ display: 'flex', borderRadius: '10px' }}>{loging}&nbsp;Sign in</Button>
        </div>
      </div>
    </>);
    if (this.state.user != undefined && this.state.user !== "undefined") {
      var defv = (cookies.get('filter') !== undefined) ? cookies.get('filter') : [];
      let tgbtns = (!this.state.loading) ? (<div className='filter-box'>
        <Form.Group>
          <Form.Control style={{ fontSize: '0.6em' }} type="text" placeholder="ค้นหา" onChange={(e) => { this.setState({ txtfilter: e.target.value }) }} />
        </Form.Group>
        <ToggleButtonGroup type="checkbox" defaultValue={defv} value={this.state.filterval} onChange={(e) => { this.filterList(e) }}>
          <ToggleButton className="rounded-0 rounded-top" style={{ fontSize: '0.5em' }} variant="outline-secondary" id="tgt-btn-1" value={1}>Operative</ToggleButton>
          <ToggleButton className="rounded-0 rounded-top" style={{ fontSize: '0.5em' }} variant="outline-secondary" id="tgt-btn-2" value={2}>Follow-Up</ToggleButton>
        </ToggleButtonGroup>
      </div>) : [];
      mainout = (<>
        <NavbarDyn lang={lang} logo={logo} icon={calendar} handler={this.selectDate} cookies={cookies} state={this.state} signout={this.signout} />
        <div className="container main-container">
          <h4 className="border border-top-0 border-start-0 border-end-0">
            <Stack direction='horizontal' gap={2}>
              <div className='me-auto'>Date: {formatDate(this.state.date.toString())} (Total: {tmp.length})</div>
              <div>{tgbtns}</div>
            </Stack>
          </h4>
          {(this.state.loading) ? (<Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />) : listData}
          <OffcanvasProgressTop handler={this.saveData} show={this.state.savingstate} value={this.state.savingprogress} title={lang.saving} />
        </div>
      </>);
    }
    return (
      <div className='App'>
        <header className='App-header-dev'>
          {mainout}
        </header>
        <div style={{ marginBottom: 50 }}></div>
        <Footer lang={lang} />
      </div>
    );
  }
}

export default App;
