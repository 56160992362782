import React, { Component } from 'react';
import { Container, Button, Form, Spinner, Alert } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";

import logo from './logo.png';
import bgHead from './bg.png';
import './App.css';
import { Navbarmod, Footer, OffcanvasProgressTop, InputValidation, RadioSwitch, InputOptional, MultiValues, SelectionValue, SelectionValues, InputBirthdateAge, InputWeightHeightBMI, InputUpload } from './react-mdkku-ui';
import { GaugeBMI } from './react-mdkku-chart';

import { lang, field, error, units, cmonths } from './config.lang';
import { endpoint } from './config.env';

const cookies = new Cookies();

//const endpoint = "https://comedkku.net/nosql/api4all.php?";
//const endpoint = "http://localhost/nosql/api4all.php?";

const initState = {
  queue: false,
  date: undefined,
  queueFetching: false,
  queueNumber: undefined,
  validation: true,
  savedstate: false,
  savingstate: false,
  generalInput: [
    'pbirth',
    'pfname',
    'plname',
    'pgender',
    'pcid',
    'inputWeight',
    'inputHeight',
  ],
  'pbirth': { rules: { birthdate: 1 }, label: field.pbirth, altLabel: field.page, value: new Date(), altValue: [0, 0, 0], valid: true, error: '', ref: React.createRef() },
  'pfname': { rules: { notEmpty: 1 }, label: field.pfname, value: '', valid: true, error: '', ref: React.createRef() },
  'plname': { rules: { notEmpty: 1 }, label: field.plname, value: '', valid: true, error: '', ref: React.createRef() },
  'pgender': { options: field.gender_opt, rules: {}, label: field.pgender, value: '', valid: true, error: '', ref: React.createRef() },
  'pcid': { rules: { type: 'digit', length: 13 }, label: field.pcid, value: '', valid: true, error: '', ref: React.createRef() },
  'inputWeight': { rules: { minNumber: 10, maxNumber: 500 }, label: field.pweight, altLabel: field.pbmi, value: 0, valid: true, error: '', ref: React.createRef() },
  'inputHeight': { rules: { minNumber: 20, maxNumber: 300 }, label: field.pheight, altLabel: field.pbmi, value: 0, valid: true, error: '', ref: React.createRef() },
}

const initInput = []

const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;
  return [year, month, day].join('-');
}

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    fetch(endpoint + "a=getlatest&b=meta&d=d0")
      .then(res => res.json())
      .then((data) => {
        if (data.formdata !== undefined) {
          let nu = { ...data.formdata[0].val, ...initState };
          nu.savingstate = false;
          nu.topicList = [...initInput, ...data.formdata[0].val.topicList];
          this.setState(nu);
          nu.generalInput.map((k, i) => {
            let tmp = cookies.get(k);
            if (tmp !== undefined && nu[k] !== undefined) {
              nu[k].value = (k === "pbirth") ? new Date(tmp.value) : tmp.value;
              nu[k].valid = tmp.valid;
              nu[k].error = tmp.error;
            }
          });
          nu.topicList.map((k, i) => {
            Object.keys(nu[k].commonInput).map((l, j) => {
              nu[k].commonInput[l].value = (nu[k].commonInput[l].type === "TextInput" || nu[k].commonInput[l].type === "OptionalTextInput") ? '' : 0;
              nu[k].commonInput[l].valid = true;
              nu[k].commonInput[l].error = '';
              nu[k].commonInput[l].ref = React.createRef();
              let tmpin = cookies.get(k + "_" + l);
              if (tmpin !== undefined) {
                nu[k].commonInput[l].value = (nu[k].commonInput[l].rules !== undefined && nu[k].commonInput[l].rules.date !== undefined) ? new Date(tmpin.value) : tmpin.value;
                nu[k].commonInput[l].valid = tmpin.valid;
                nu[k].commonInput[l].error = tmpin.error;
              }
            });
          });
          if (cookies.get('queueNumber') !== undefined && cookies.get('queueNumber') !== "undefined") {
            nu.queue = true;
            nu.queueNumber = cookies.get('queueNumber');
            nu.date = new Date(cookies.get('queueDate'));
          }
          this.setState(nu);
          //this.validateInput("pbirth",this.state.pbirth.value);
          this.state.generalInput.map((k, i) => {
            if (this.state[k] === undefined) {
              alert(k + "::" + this.state[k]);
            } else {
              this.validateCommonInput(this.state[k].rules, this.state[k], this.state[k].value, k, true);
            }
          });
          this.state.topicList.map((l, j) => {
            Object.keys(this.state[l].commonInput).map((k, i) => {
              if (this.state[l].commonInput[k] === undefined) {
                alert(k + "::" + this.state[k]);
              } else {
                //console.log(this.state[l].commonInput[k]);
                this.validateCommonInput(this.state[l].commonInput[k].rules, this.state[l].commonInput[k], this.state[l].commonInput[k].value, l + '_' + k, true);
              }
            });
          });
        }
        console.log(this.state);
        this.setState({ loading: false });
      });
  }

  componentDidMount() {
    this.interval = setInterval(() => { this.updateQueue("queue", new Date()); }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateQueue = (e) => {
    let formdat = "a=getall&b=ins&w=" + formatDate(this.state.date);
    if (this.state.queue && !this.state.savedstate && this.state.date !== undefined) {
      fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        body: formdat
      })
        .then(res => res.json())
        .then((data) => {
          //console.log(data.length+1,data);
          cookies.set('queueNumber', (data.length + 1), { path: '/' });
          this.setState({ queueNumber: (data.length + 1) });
        });
    }
  }

  setCookie = (t, e) => {
    let formdat = "a=getall&b=ins&w=" + formatDate(e);
    if (t === "queue") {
      this.setState({ queueFetching: true });
      fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        body: formdat
      })
        .then(res => res.json())
        .then((data) => {
          console.log(data.length + 1, data);
          cookies.set('queue', true, { path: '/' });
          cookies.set('queueNumber', (data.length + 1), { path: '/' });
          cookies.set('queueDate', e, { path: '/' });
          this.setState({ date: e, queueNumber: (data.length + 1), queueFetching: false });
        });
    } else if (t === "data") {
      cookies.set('dat', this.state.dat, { path: '/' });
    } else {
      //let obj = {options:e.options,rules:e.rules,lable:e.label,altLabel:e.altLabel,value:e.value,altValue:e.altValue,ref:{}};
      let obj = e;
      obj.ref = {};
      cookies.set(t, obj, { path: '/' });
    }
  }

  clearCookies = () => {
    this.state.generalInput.map((t, i) => {
      cookies.remove(t);
    });
    this.state.topicList.map((t, i) => {
      Object.keys(this.state[t].commonInput).map((k, j) => {
        cookies.remove(t + "_" + k);
      });
    });
    this.clearQueue();
    window.location.reload(false);
  }

  validateInput = (t, e) => {
    let prev = this.state.pbirth;
    let chk = true;
    if (t === "pbirth") {
      if (e === "") {
        prev.valid = false;
        prev.error = error.is_empty;
      } else {
        prev.value = e;
        let age = this.calculateAge(e);
        prev.altValue = age;
        if (age[0] < 2) {
          prev.valid = false;
          prev.error = error.age_too_low;
        } else {
          prev.valid = true;
          prev.error = '';
        }
      }
    }
    let prevstate = this.state;
    prevstate[t] = prev;
    this.setState(prevstate);
    return 1;
  }

  validateCommonInput = (rules, target, e, statekey, firsttime = false) => {
    let arr = statekey.split("_");
    target.value = e;
    if (rules !== undefined) {
      Object.keys(rules).map((k, i) => {
        if (k === "minNumber") {
          if (!isNaN(rules[k]) && rules[k] !== null && e < rules[k]) {
            target.error = (i > 0) ? target.error + "," + error.is_not_less_than + " " + rules[k] : error.is_not_less_than + " " + rules[k];
            target.valid = false;
          } else {
            target.error = (i > 0) ? target.error : '';
            target.valid = (i > 0) ? target.valid & true : true;
          }
        } else if (k === "maxNumber") {
          if (!isNaN(rules[k]) && rules[k] !== null && e > rules[k]) {
            target.error = (i > 0) ? target.error + "," + error.is_not_more_than + " " + rules[k] : error.is_not_more_than + " " + rules[k];
            target.valid = false;
          } else {
            target.error = (i > 0) ? target.error : '';
            target.valid = (i > 0) ? target.valid & true : true;
          }
        } else if (k === "notEmpty") {
          if (e === "") {
            target.error = (i > 0) ? target.error + "," + error.is_empty : error.is_empty;
            target.valid = false;
          } else {
            target.error = (i > 0) ? target.error : '';
            target.valid = (i > 0) ? target.valid & true : true;
          }
        } else if (k === "type") {
          if (rules[k] === "digit") {
            if (isNaN(e)) {
              target.error = (i > 0) ? target.error + "," + error.not_a_number : error.not_a_number;
              target.valid = false;
            } else {
              target.error = (i > 0) ? target.error : '';
              target.valid = (i > 0) ? target.valid & true : true;
            }
          }
        } else if (k === "minLength") {
          if (!isNaN(rules[k]) && rules[k] !== null && e.length < rules[k]) {
            target.error = (i > 0) ? target.error + "," + error.length_less_than + " " + rules[k] : error.length_less_than + " " + rules[k];
            target.valid = false;
          } else {
            target.error = (i > 0) ? target.error : '';
            target.valid = (i > 0) ? target.valid & true : true;
          }
        } else if (k === "maxLength") {
          if (!isNaN(rules[k]) && rules[k] !== null && e.length > rules[k]) {
            target.error = (i > 0) ? target.error + "," + error.length_more_than + " " + rules[k] : error.length_more_than + " " + rules[k];
            target.valid = false;
          } else {
            target.error = (i > 0) ? target.error : '';
            target.valid = (i > 0) ? target.valid & true : true;
          }
        } else if (k === "length") {
          if (!isNaN(rules[k]) && rules[k] !== null && e.length !== rules[k]) {
            target.error = (i > 0) ? target.error + "," + error.length_not_equals.replace("{}", rules[k]) : error.length_not_equals.replace("{}", rules[k]);
            target.valid = false;
          } else {
            target.error = (i > 0) ? target.error : '';
            target.valid = (i > 0) ? target.valid & true : true;
          }
        } else if (k === "birthdate") {
          if (e === "") {
            target.error = (i > 0) ? target.error + "," + error.is_empty : error.is_empty;
            target.valid = false;
          } else {
            target.value = e;
            let age = this.calculateAge(e);
            target.altValue = age;
            if (age[0] < 2) {
              target.error = (i > 0) ? target.error + "," + error.age_too_low : error.age_too_low;
              target.valid = false;
            } else {
              target.valid = true;
              target.error = (i > 0) ? target.error : '';
            }
          }
        }
      });
    }
    target.error = (target.error.charAt(0) === ",") ? target.error.substring(1) : target.error;
    let prevstate = this.state;
    if (arr.length === 1) {
      prevstate[statekey] = target;
    } else {
      prevstate[arr[0]].commonInput[arr[1]] = target;
    }
    this.setState(prevstate);
    if (!firsttime) this.setCookie(statekey, target);
    return 1;
  }

  validateSingleSelection = (rules, target, e, statekey) => {
    let arr = statekey.split("_");
    let prevstate = this.state;
    if (arr.length == 2) {
      Object.keys(prevstate[arr[0]].commonInput).map((k, i) => {
        if (prevstate[arr[0]].commonInput[k].type === "RadioSwitch") {
          if (prevstate[arr[0]].commonInput[k].id === arr[1]) {
            prevstate[arr[0]].commonInput[k].value = 1;
          } else {
            prevstate[arr[0]].commonInput[k].value = 0;
          }
        }
      });
      this.setState(prevstate);
    }
  }

  validateMultiValue = (rules, target, e, statekey, fixed = -1) => {
    let chk = -1;
    let out = target.value;
    if (fixed > -1) {
      target.value[fixed] = e;
    } else {
      let out = target.value.map((k, i) => {
        if (k === e) chk = i;
        return k;
      });
      if (chk === -1) {
        out.push(e);
      } else {
        out.splice(chk, 1);
      }
      target.value = out;
    }
    this.setState(target);
    this.setCookie(statekey, target);
    return 1;
  }

  clearQueue = () => {
    cookies.set('queueNumber', undefined);
    this.setState({ queue: false, queueNumber: undefined, date: undefined, savedstate: false });
  }

  entering = (e) => {
    e.style.fontFamily = 'Pridi';
  }

  calculateAge = (d) => {
    let birthdate = new Date(d);
    let timenow = new Date();
    let yearAge = (timenow.getYear() - birthdate.getYear());
    let monthAge = (timenow.getMonth() - birthdate.getMonth());
    let dayAge = (timenow.getDate() - birthdate.getDate());
    let daysInMonth = new Date(birthdate.getYear(), birthdate.getMonth() + 1, 0).getDate();
    if (dayAge < 0) {
      dayAge += daysInMonth;
      monthAge -= 1;
    }
    if (monthAge < 0) {
      monthAge += 12;
      yearAge -= 1;
    }
    return [yearAge, monthAge, dayAge];
  }

  calculateBMI = (w, h) => {
    h = h / 100;
    return (w / (h * h)).toFixed(2);
  }

  checkValidation = () => {
    let valid = true;
    this.state.generalInput.map((k, i) => { valid &= (this.state[k].valid) });
    console.log(valid);
    if (!valid) {
      this.setState({ validation: false });
    } else {
      this.setState({ validation: true });
      this.setState({ savingprogress: 0, savingstate: true });
    }
  }

  removeRef = (obj) => {
    let outobj = {};
    Object.keys(obj).map((k, i) => {
      if (k !== 'ref') {
        if (typeof obj[k] === 'object' && obj[k] !== null) {
          console.log(k, obj[k], typeof obj[k]);
          if (obj[k].map !== undefined) {
            outobj[k] = obj[k].map((l, j) => { return l });
          } else if (obj[k] instanceof Date) {
            outobj[k] = obj[k];
          } else {
            outobj[k] = this.removeRef(obj[k]);
          }
        } else {
          outobj[k] = obj[k];
        }
      }
    });
    return outobj;
  }

  formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  saveData = (p) => {
    let fullname = this.state.pfname.value + " " + this.state.plname.value;
    let dat = this.removeRef(this.state);
    let jstr0 = JSON.stringify(dat);
    let url = endpoint + "a=set&b=ins&w=" + this.formatDate(this.state.date) + "&w1=" + fullname + "&d=d0&f=data";
    let formdat = "v=" + jstr0;
    //this.setState({savingprogress:20});
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
      },
      body: formdat
    })
      .then(res => res.json())
      .then(data => {
        cookies.set('savedstate', true, { path: '/' });
        this.setState({ savingprogress: 100, savingstate: false, savedstate: true });
        setTimeout(() => { this.clearCookies() }, 1000);
      });
  }

  render() {
    let men1 = (this.state.loading) ? [
      <Container className="p-5 text-center">
        <Button variant="primary" className="" size="lg" disabled>
          <Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />
        </Button>
      </Container>
    ] : [
      <Container className="p-5 text-center">
        <Button variant="primary" className="" size="lg" onClick={(e) => { this.setState({ queue: true }) }}>
          <i className="bi bi-alarm-fill fs-1"></i><br />{lang.queue}
        </Button>
      </Container>
    ];
    let cancleBtn = '';
    let formP = '';
    let formA = '';
    let str_seldate = lang.select_date;
    let readonly = false;
    if (this.state.queueNumber !== undefined) {
      cancleBtn = [<Button variant="success" size="lg" onClick={(e) => { this.clearQueue() }}>{lang.your_queue}<br />{this.state.queueNumber}<br />{lang.cancel_queue}</Button>];
      formA = this.state.topicList.map((k, i) => {
        let c = (i % 2 === 1) ? "odd" : "even";
        let radiovalidate = (this.state[k].single) ? this.validateSingleSelection : this.validateCommonInput;
        let ele = Object.keys(this.state[k].commonInput).map((l, j) => {
          let eleout = [];
          let w = true;
          if (this.state[k].commonInput[l].with !== undefined && this.state[k].commonInput[l].with !== "") {
            let arr = this.state[k].commonInput[l].with.split("_");
            if (arr.length > 1) {
              w = this.state[arr[0]].commonInput[arr[1]].value;
            }
          }
          if (this.state[k].commonInput[l].without !== undefined && this.state[k].commonInput[l].without !== "") {
            let arr = this.state[k].commonInput[l].without.split("_");
            if (arr.length > 1) {
              w = w & !this.state[arr[0]].commonInput[arr[1]].value;
            }
          }
          if (this.state[k].commonInput[l].type === "RadioSwitch") {
            eleout = <div className="d-flex"><RadioSwitch statekey={k + "_" + l} validation={radiovalidate} target={this.state[k].commonInput[l]} show={w} /></div>;
          } else if (this.state[k].commonInput[l].type === "TextInput") {
            eleout = <div className="d-flex"><InputValidation type="text" statekey={k + "_" + l} validation={this.validateCommonInput} target={this.state[k].commonInput[l]} show={w} /></div>;
          } else if (this.state[k].commonInput[l].type === "NumberInput") {
            eleout = <div className="d-flex"><InputValidation type="number" statekey={k + "_" + l} validation={this.validateCommonInput} target={this.state[k].commonInput[l]} show={w} /></div>;
          } else if (this.state[k].commonInput[l].type === "OptionalTextInput") {
            eleout = <div className="d-flex"><InputOptional type="text" statekey={k + "_" + l} validation={this.validateCommonInput} target={this.state[k].commonInput[l]} show={w} /></div>;
          } else if (this.state[k].commonInput[l].type === "OptionalNumberInput") {
            eleout = <div className="d-flex"><InputOptional type="number" statekey={k + "_" + l} validation={this.validateCommonInput} target={this.state[k].commonInput[l]} show={w} /></div>;
          } else if (this.state[k].commonInput[l].type === "FileInput") {
            eleout = <div className="d-flex"><InputUpload type="file" statekey={k + "_" + l} validation={this.validateCommonInput} target={this.state[k].commonInput[l]} show={w} /></div>;
          }
          return (eleout);
        });
        return (
          <div className={"mt-2 " + c + "-row"}>
            <h4 className="mt-2 mb-0">{this.state[k].title}</h4>
            {ele}
          </div>
        );
      });
      formP = [
        <div>
          <Form className="mt-4 w-75">
            <InputValidation type="text" statekey="pfname" validation={this.validateCommonInput} target={this.state.pfname} />
            <InputValidation type="text" statekey="plname" validation={this.validateCommonInput} target={this.state.plname} />
            <InputValidation type="text" statekey="pcid" validation={this.validateCommonInput} target={this.state.pcid} />
            <InputBirthdateAge statekey="pbirth" validation={this.validateCommonInput} target={this.state.pbirth} />
            <SelectionValue statekey="pgender" validation={this.validateCommonInput} target={this.state.pgender} />
            <InputWeightHeightBMI statekeyWeight="inputWeight" statekeyHeight="inputHeight" validation={this.validateCommonInput} targetWeight={this.state.inputWeight} targetHeight={this.state.inputHeight} />
          </Form>
          {formA}
          <div className="d-grid mt-3">
            <Alert variant="danger" show={!this.state.validation} onClose={(e) => { this.setState({ validation: true }) }} dismissible>
              <Alert.Heading></Alert.Heading>
              {lang.please_validation}
            </Alert>
            <Alert variant="success" show={this.state.validation && this.state.savedstate} onClose={(e) => { this.setState({ validation: true }) }} dismissible>
              <Alert.Heading></Alert.Heading>
              {lang.save_succesful}
            </Alert>
            <Button variant="success" className="rounded-pill" size="lg" onClick={(e) => { this.checkValidation() }}>{lang.confirm}</Button>
          </div>
          <OffcanvasProgressTop handler={this.saveData} show={this.state.savingstate} value={this.state.savingprogress} title={lang.saving} />
        </div>
      ];
      str_seldate = lang.your_select_date;
      readonly = true;
    }
    if (this.state.queue) {
      let ctime = new Date();
      let cyears = [];
      for (let i = 2010; i <= ctime.getYear() + 1900; i++) {
        cyears.push(i);
      }

      men1 = (!this.state.queueFetching) ? [
        <Container fluid="sm" className="pt-5 pb-5 p-lg-5 pe-3">
          {cancleBtn}
          <h3 className="mt-3">{str_seldate}</h3>
          <DatePicker
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div>
                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
                <select value={date.getYear() + 1900} onChange={({ target: { value } }) => changeYear(value)}>{cyears.map((opt) => (<option key={opt} value={opt}>{opt + 543}</option>))}</select>
                <select value={cmonths[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(cmonths.indexOf(value))}>{cmonths.map((opt) => (<option key={opt} value={opt}>{opt}</option>))}</select>
                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
              </div>
            )} selected={this.state.date} onSelect={(e) => { this.setCookie("queue", e) }} readOnly={readonly} dateFormat="dd/MM/yyyy" />
          {formP}
        </Container>
      ] : [<Container className="p-5 text-center"><Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" /></Container>];
    }

    return (
      <div className="App">
        <header className="App-header">
          <Navbarmod lang={lang} logo={logo} />
          {men1}
        </header>
        <Footer lang={lang} />
      </div>
    );
  }
}

export default App;
